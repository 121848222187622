.game-intro{
  .animation-flames{
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .game-navbar{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 15vw);
    padding: 5vw 7.5vw;
    left: 0px;
    top: 0px;
    background: transparent;
    position: relative;
    z-index: 3;

    h1{
      margin: 0px;
      font-family: SohneBreitHalbfettKursiv;
      font-size: 5.625vw;
      line-height: 6.875vw;
      color: $content-primary;
      text-transform: uppercase;
    }

    a.close-button{
      padding: 3.125vw;
      width: max-content;

      .close-icon{
        width: 3.75vw;
        height: 3.75vw;
      }
    }
  }
  .game-intro-container{
    padding: 0px 7.5vw 12.5vw 7.5vw;
    height: calc(100% - 32.5vw);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-around;
    position: initial;
    z-index: 1;

    .slider-container{
      margin: auto;
      width: 100%;
      height: max-content;
    }

    .mySwiper{
      height: 112.5vw;
      overflow: inherit;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      max-height: 82vh;

      .swiper-wrapper{
        height: 91.5% !important;
        max-height: 78vh;
      }

      .swiper-slide{
        padding: 7.5vw 0px;
        box-shadow: 0px 0.625vw 3.75vw 0px #0021441A;
        background: $background-primary;
        border-radius: 2.5vw;
        overflow-y: auto;
        height: 87.5vw;
        max-height: 65vh;

        h2, span.h3, p{
          padding: 0px 8vw;
        }

        h2{
          font-family: SohneBreitHalbfettKursiv;
          font-size: 7.5vw;
          line-height: 8.75vw;
          letter-spacing: 0em;
          text-align: center;
          color: $content-secondary;
          margin: 0px;
        }

        span.h3{
          font-family: QueensCondensedBoldItalic;
          font-size: 11.25vw;
          line-height: 11.25vw;
          letter-spacing: 0em;
          text-align: center;
          color: $content-primary;
          margin-top: 0px;
          margin-bottom: 0px;
          display: block;
        }

        span.h4{
          font-family: QueensCondensedBoldItalic;
          font-size: 7.5vw;
          line-height: 8.75vw;
          letter-spacing: 0em;
          text-align: center;
          color: #141414;
          margin: 0px;
          display: block;
        }

        p{
          font-family: SohneBuch;
          font-size: 5vw;
          line-height: 6.5625vw;
          letter-spacing: 0em;
          text-align: center;
          color: $content-secondary;
          margin-top: 5vw;
          margin-bottom: 0px;
        }

        .icon{
          width: 100%;
          height: 30vw;
          object-fit: contain;
          object-position: center;
          margin-bottom: 10.625vw;
        }

        .extintor-icon{
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: row;

          svg{
            width: auto !important;
            height: 100%;
            border-radius: 100%;
            box-shadow: 0px 2px 12px 0px rgba(0, 33, 68, 0.10);
          }
        }
      }

      .swiper-pagination{
        bottom: 0px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        position: relative !important;
        margin-top: 3vw;

        .swiper-pagination-bullet-active{
          background-color: $background-secondary;
        }

        .swiper-pagination-bullet:not(.swiper-pagination-bullet-active){
          background-color: #AFAFAF;
        }

        .swiper-pagination-bullet{
          opacity: 1;
          margin: 0px 0.78125vw;
          width: 2.5vw;
          height: 2.5vw;
        }
      }
    }

    .button-block{
      padding: 3.125vw 0px;
      background-color: $background-secondary;
      color: $content-on-color;
      font-family: SohneBreitDreiviertelfett;
      font-size: 5vw;
      line-height: 6.25vw;
      letter-spacing: 0em;
      text-align: center;
      border: none;
      width: 100%;
      margin-top: 3vw;
      z-index: 1;
    }
  }
}