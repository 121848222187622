.display-led-none{
  display: none !important;
}

.logo-qr-container{
  padding: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  justify-content: space-between;
  opacity: 0;
  transition: opacity 1s;

  .logo-image-display{
    width: 120px;
    object-fit: cover;
    opacity: 0;
  }

  .qr-right-block{
    padding: 0px 5px 0px 45px;
    width: 132px;
    border-left: 1px solid #FDFDFD;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    
    .url-text{
      font-family: SohneBuch;
      font-size: 12px;
      line-height: 13px;
      letter-spacing: 0em;
      text-align: center;
      color: #FDFDFD;
      margin-top: 0px;
      margin-bottom: 33px;
    }

    .qr-block{
      width: 132px;
      height: 132px;

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    .scanner-text{
      font-family: SohneBuch;
      font-size: 16px;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      color: #FDFDFD;
      margin-top: 12px;
      margin-bottom: 0px;
    }
  }
}

.logo-qr-container.visible{
  opacity: 1;
}

// Primera pantalla 1280px
.led-1-text-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;

  p{
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: SohneBreitDreiviertelfettKursiv;
    text-transform: uppercase;
    color: #FDFDFD;
    opacity: 0;
    transform: translateY(200%);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  }

  .led-1-full-width-block{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 1s ease-in-out;
    
    p{
      font-size: 32px;
      line-height: 35px;
      letter-spacing: 0em;
      text-align: center;
      // width: 480px;
    }
    p:nth-child(1){
      transition-delay: 0.5s;
    }
    p:nth-child(2){
      transition-delay: 0.60s;
    }
    p:nth-child(3){
      transition-delay: 0.70s;
    }
    p:nth-child(4){
      transition-delay: 0.80s;
    }
  }

  .led-1-full-width-expo{
    height: max-content;

    p, span{
      color: #FDFDFD;
      text-align: center;
    }
    p:nth-child(1){
      font-family: QueensCondensedBoldItalic;
      font-size: 76px;
      line-height: 76px;
      letter-spacing: 0em;
      transition-delay: 0.5s;
    }
    p:nth-child(2){
      font-family: SohneBreitDreiviertelfettKursiv;
      font-size: 51px;
      line-height: 43px;
      letter-spacing: 0em;   
      text-transform: uppercase;   
      transition-delay: 0.80s;
    }
    p:nth-child(3){
      font-family: SohneBuch;
      font-size: 19px;
      line-height: 19px;
      letter-spacing: 0em;
      margin-top: 32px;
      margin-bottom: 13px;
      transition-delay: 1s;
    }
    p:nth-child(4){
      font-family: QueensCondensedBoldItalic;
      font-size: 32px;
      line-height: 32px;
      letter-spacing: 0em;      
      transition-delay: 1.2s;
    }
  }

  .led-1-full-width-expo{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .led-1-left-block-1, .led-1-right-block-2{
    width: calc(50% - 10px);
    height: calc(100% - 10px);
  }

  .led-1-right-block-1, .led-1-left-block-2, .led-1-left-block-3, .led-1-right-block-3{
    width: 50%;
    height: 100%;
  }

  .led-1-left-block-1, .led-1-right-block-1, .led-1-left-block-2, .led-1-right-block-2, .led-1-left-block-3, .led-1-right-block-3{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 1s ease-in-out;

    p{
      font-size: 28px;
      line-height: 31px;
      letter-spacing: 0em;
      text-align: left;
      // width: 424px;
    }
    p:nth-child(1){
      transition-delay: 0.5s;
    }
    p:nth-child(2){
      transition-delay: 0.60s;
    }
    p:nth-child(3){
      transition-delay: 0.70s;
    }
    p:nth-child(4){
      transition-delay: 0.80s;
    }

    img, video{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .led-1-left-block-1, .led-1-right-block-2, .led-1-full-width-block{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 5px;
  }

  .led-1-left-block-1, .led-1-right-block-2{
    align-items: center;
  }

  .led-1-full-width-block, .led-1-left-block-1, .led-1-right-block-2{
    background-color: $primary;
  }

  .led-1-right-block-1, .led-1-right-block-2, .led-1-right-block-3{
    right: 0;
  }

  .led-1-left-block-1, .led-1-left-block-2, .led-1-left-block-3{
    left: 0;
  }

  .rates-container{
    width: 100%;
    height: 100%;
    transition: transform 1s ease-in-out;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .led-1-right-block-1, .led-1-left-block-2, .led-1-right-block-3, .rates-container{
    transform: translateY(-100%); /* Mueve el div hacia abajo fuera de la pantalla */
  }

  .led-1-full-width-block, .led-1-left-block-1, .led-1-right-block-2, .led-1-left-block-3{
    transform: translateY(100%); /* Mueve el div hacia arriba fuera de la pantalla */
  }

  .video-display{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .video-display.visible{
    display: block;
  }

  .video-display, .video-display.out{
    display: none;
  }

  .led-1-full-width-block.visible, .led-1-left-block-1.visible, .led-1-right-block-1.visible, .led-1-left-block-2.visible, .led-1-right-block-2.visible, .led-1-left-block-3.visible, .led-1-right-block-3.visible, .rates-container.visible {
    transform: translateY(0);

    p{
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .led-1-full-width-expo.out, .led-1-full-width-text.out{
    transform: translateY(-150%);
    transition-delay: 1s;

    p{
      opacity: 0;
      transform: translateY(-150%);
      transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    }
    p:nth-child(1){
      transition-delay: 0s;
    }
    p:nth-child(2){
      transition-delay: 0.10s;
    }
    p:nth-child(3){
      transition-delay: 0.20s;
    }
    p:nth-child(4){
      transition-delay: 0.30s;
    }
  }
  
  .led-1-right-block-1.out, .led-1-left-block-2.out, .led-1-right-block-3.out, .rates-container.out{
    transform: translateY(150%);
    transition-delay: 0.7s;
  }

  .led-1-full-width-block.out, .led-1-left-block-1.out, .led-1-right-block-2.out, .led-1-left-block-3.out{
    transform: translateY(-150%);
    transition-delay: 0.7s;

    p{
      opacity: 0;
      transform: translateY(-150%);
      transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    }
    p:nth-child(1){
      transition-delay: 0s;
    }
    p:nth-child(2){
      transition-delay: 0.10s;
    }
    p:nth-child(3){
      transition-delay: 0.20s;
    }
    p:nth-child(4){
      transition-delay: 0.30s;
    }
  }
}

// Segunda pantalla 2560px
.led-2-text-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  position: relative;

  p{
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: SohneBreitDreiviertelfettKursiv;
    text-transform: uppercase;
    color: #FDFDFD;
    opacity: 0;
    transform: translateY(200%);
    transition: opacity 1s ease-in-out, transform 1s ease-in-out;
  }

  .led-2-full-width-block{
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 1s ease-in-out;
    
    p{
      font-size: 40px;
      line-height: 44px;
      letter-spacing: 0em;
      text-align: center;
      // width: 480px;
    }
    p:nth-child(1){
      transition-delay: 0.5s;
    }
    p:nth-child(2){
      transition-delay: 0.60s;
    }
    p:nth-child(3){
      transition-delay: 0.70s;
    }
    p:nth-child(4){
      transition-delay: 0.80s;
    }
  }

  .led-2-streaming-block{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
  }

  .led-2-full-width-expo{
    height: max-content;

    p, span{
      color: #FDFDFD;
      text-align: center;
    }
    p:nth-child(1){
      font-family: QueensCondensedBoldItalic;
      font-size: 96px;
      line-height: 96px;
      letter-spacing: 0em;
      transition-delay: 0.5s;
    }
    p:nth-child(2){
      font-family: SohneBreitDreiviertelfettKursiv;
      font-size: 64px;
      line-height: 54px;
      letter-spacing: 0em;   
      text-transform: uppercase;   
      transition-delay: 0.80s;
    }
    p:nth-child(3){
      font-family: SohneBuch;
      font-size: 24px;
      line-height: 24px;
      letter-spacing: 0em;
      margin-top: 40px;
      margin-bottom: 16px;
      transition-delay: 1s;
    }
    p:nth-child(4){
      font-family: QueensCondensedBoldItalic;
      font-size: 40px;
      line-height: 40px;
      letter-spacing: 0em;      
      transition-delay: 1.2s;
    }
  }

  .led-2-full-width-expo{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }

  .led-2-left-block-1, .led-2-right-block-2{
    width: calc(50% - 10px);
    height: calc(100% - 10px);
  }

  .led-2-right-block-1, .led-2-left-block-2, .led-2-left-block-3, .led-2-right-block-3{
    width: 50%;
    height: 100%;
  }

  .led-2-left-block-1, .led-2-right-block-1, .led-2-left-block-2, .led-2-right-block-2, .led-2-left-block-3, .led-2-right-block-3{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 1s ease-in-out;

    p{
      font-size: 40px;
      line-height: 44px;
      letter-spacing: 0em;
      text-align: left;
      // width: 424px;
    }
    p:nth-child(1){
      transition-delay: 0.5s;
    }
    p:nth-child(2){
      transition-delay: 0.60s;
    }
    p:nth-child(3){
      transition-delay: 0.70s;
    }
    p:nth-child(4){
      transition-delay: 0.80s;
    }

    img, video{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .led-2-left-block-1, .led-2-right-block-2, .led-2-full-width-block{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    padding: 5px;
  }

  .led-2-left-block-1, .led-2-right-block-2{
    align-items: center;
  }

  .led-2-full-width-block, .led-2-left-block-1, .led-2-right-block-2{
    background-color: $primary;
  }

  .led-2-right-block-1, .led-2-right-block-2, .led-2-right-block-3{
    right: 0;
  }

  .led-2-left-block-1, .led-2-left-block-2, .led-2-left-block-3{
    left: 0;
  }

  .rates-container-2{
    width: 100%;
    height: 100%;
    transition: transform 1s ease-in-out;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .led-2-right-block-1, .led-2-left-block-2, .led-2-right-block-3, .rates-container-2{
    transform: translateY(-100%); /* Mueve el div hacia abajo fuera de la pantalla */
  }

  .led-2-full-width-block, .led-2-left-block-1, .led-2-right-block-2, .led-2-left-block-3{
    transform: translateY(100%); /* Mueve el div hacia arriba fuera de la pantalla */
  }

  .video-display-2{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .video-display-2.visible{
    display: block;
  }

  .video-display-2, .video-display-2.out{
    display: none;
  }

  .led-2-full-width-block.visible, .led-2-left-block-1.visible, .led-2-right-block-1.visible, .led-2-left-block-2.visible, .led-2-right-block-2.visible, .led-2-left-block-3.visible, .led-2-right-block-3.visible, .rates-container-2.visible {
    transform: translateY(0);

    p{
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .led-2-full-width-expo.out, .led-2-full-width-text.out{
    transform: translateY(-150%);
    transition-delay: 1s;

    p{
      opacity: 0;
      transform: translateY(-150%);
      transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    }
    p:nth-child(1){
      transition-delay: 0s;
    }
    p:nth-child(2){
      transition-delay: 0.10s;
    }
    p:nth-child(3){
      transition-delay: 0.20s;
    }
    p:nth-child(4){
      transition-delay: 0.30s;
    }
  }
  
  .led-2-right-block-1.out, .led-2-left-block-2.out, .led-2-right-block-3.out, .rates-container-2.out{
    transform: translateY(150%);
    transition-delay: 0.7s;
  }

  .led-2-full-width-block.out, .led-2-left-block-1.out, .led-2-right-block-2.out, .led-2-left-block-3.out{
    transform: translateY(-150%);
    transition-delay: 0.7s;

    p{
      opacity: 0;
      transform: translateY(-150%);
      transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    }
    p:nth-child(1){
      transition-delay: 0s;
    }
    p:nth-child(2){
      transition-delay: 0.10s;
    }
    p:nth-child(3){
      transition-delay: 0.20s;
    }
    p:nth-child(4){
      transition-delay: 0.30s;
    }
  }

  .led-2-full-width-block.out, .led-2-left-block-1.out, .led-2-right-block-2.out, .led-2-left-block-3.out{
    transform: translateY(-150%);
    transition-delay: 0.7s;

    p{
      opacity: 0;
      transform: translateY(-150%);
      transition: opacity 1s ease-in-out, transform 1s ease-in-out;
    }
    p:nth-child(1){
      transition-delay: 0s;
    }
    p:nth-child(2){
      transition-delay: 0.10s;
    }
    p:nth-child(3){
      transition-delay: 0.20s;
    }
    p:nth-child(4){
      transition-delay: 0.30s;
    }
  }
}
