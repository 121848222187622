.game{
  .animation-flames{
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    pointer-events: none;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  
  .game-navbar.flames-info{
    background-color: $background-primary;

    h1{
      color: #E30613;
    }
  }

  .game-navbar.extintor-info{
    background-color: #E30613;

    h1{
      color: $content-on-color;
    }
  }

  .game-navbar{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 15vw);
    padding: 5vw 7.5vw;
    left: 0px;
    top: 0px;
    background: $background-primary;

    h1{
      margin: 0px;
      font-family: SohneBreitHalbfettKursiv;
      font-size: 5.625vw;
      line-height: 6.875vw;
      color: $content-primary;
      text-transform: uppercase;
    }

    a.close-button{
      padding: 3.125vw;
      width: max-content;

      .close-icon{
        width: 3.75vw;
        height: 3.75vw;
      }
    }
  }
  
  .game-container{
    height: calc(100% - 19.06vw);
    flex-grow: 1;
    position: relative;
    z-index: 1;

    .extintor-info{
      background: #E30613;

      .info-card{
        background: #E30613;

        .text-container{
          p{
            color: $content-on-color;
          }
        }
      }

      .button-block{
        background-color: $background-primary;
        color: #E30613;
      }
    }

    .flames-info{
      .info-card{
        background: $background-primary;

        .text-container{
          p{
            color: #E30613;
          }
        }
      }

      .button-block{
        background-color: #E30613;
        color: $content-on-color;
      }
    }

    .game-info{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: calc(100% - 10.3125vw);
      padding: 0px 0px 10.3125vw;
       
      .info-card{
        padding: 0px 7.5vw;
        width: calc(100% - 30vw);
        border-radius: 2.5vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-y: auto;
        
        .img-container{
          width: 100%;
          height: 50%;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          flex-wrap: nowrap;

          .icon{
            width: 100%;
            height: 31.25vw;
            object-fit: contain;
            object-position: center;
          }
        }

        .text-container{
          height: 50%;

          p{
            font-family: SohneBreitHalbfettKursiv;
            font-size: 5.625vw;
            line-height: 6.875vw;
            letter-spacing: 0em;
            text-align: center;
            margin-top: 10vw;
            margin-bottom: 0px;
          }
        }
      }

      .button-block{
        padding: 3.125vw 0px;
        font-family: SohneBreitDreiviertelfett;
        font-size: 5vw;
        line-height: 6.25vw;
        letter-spacing: 0em;
        text-align: center;
        border: none;
        margin-top: 5.625vw;
        width: calc(100% - 15vw);
      }
    }

    .game-block{
      height: calc(100% - 8.75vw);
      padding: 2.5vw 7.5vw 6.25vw;
      position: relative;
      
      .artwork{
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 2.5vw;
        overflow: hidden;

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }

        .image-filter-artwork{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 50%);
        }

        // .image-filter{
        //   width: 100%;
        //   height: 100%;
        //   background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 10.24%, rgba(0, 0, 0, 0) 42.35%), linear-gradient(0deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
        //   position: absolute;
        //   top: 0;
        //   bottom: 0;
        //   left: 0;
        //   right: 0;
        // }
      }

      .game-chrono{
        position: absolute;
        top: 8.4375vw;
        left: 0;
        right: 0;
        width: calc(100% - 26.25vw);
        margin: auto;

        p{
          margin-top: 0px;
          margin-bottom: 2.5vw;
          font-family: SohneBuch;
          font-size: 4.375vw;
          line-height: 5vw;
          letter-spacing: 0em;
          text-align: center;
          color: $content-on-color;
        }
      }

      .obra-quemada-filter, .obra-salvada-filter{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
      }

      .obra-quemada-filter{
        background: linear-gradient(0deg, rgba(240, 2, 20, 0.8), rgba(240, 2, 20, 0.8));
        
        span{
          font-family: QueensCondensedBoldIt;
          font-size: 11.25vw;
          line-height: 11.25vw;
          letter-spacing: 0em;
          text-align: center;
          color: $content-on-color;
          display: block;
        }
      }

      .obra-salvada-filter{
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8));
        
        span{
          font-family: QueensCondensedBoldIt;
          font-size: 11.25vw;
          line-height: 11.25vw;
          letter-spacing: 0em;
          text-align: center;
          color: $content-primary;
          display: block;
        }
      }
    }
  }

  .game-buttons{
    position: absolute;
    z-index: 4;
    bottom: 13.75vw;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    button{
      border: none;
      padding: 0px;
      margin: 0px 2.5vw;
      border-radius: 25vw;
      width: 20vw;
      height: 20vw;
      transition: transform 0.5s;

      .icon{
        height: 12.5vw;
        transition: transform 0.5s;
      }
    }

    .extractor-button{
      background: $background-primary;
      box-shadow: 0px 0.625vw 3.75vw 0px #0021441A;
      
      path{
        fill: #E30613;
      }
    }
    .flames-button{
      background: #E30613;
        
      path{
        fill: $content-on-color;
      }
    }

    button.active{
      transform: scale(1.3125);
      
      .flames-icon{
        transform: rotate(-15deg);
      }
      .extractor-icon{
        transform: rotate(15deg);
      }
    }
  }
}