.session-expired{
  z-index: 3;
  position: absolute;
  width: calc(100vw - 27.5vw);
  height: calc(100vh - 10px);
  padding: 5px 13.75vw;
  background: #E30613;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;

  .texts-container{
    overflow-y: auto;

    .session-expired-h1{
      color: $content-on-color;
      font-family: QueensCondensedBoldItalic;
      font-size: 18.75vw;
      line-height: 17.8125vw;
      letter-spacing: 0em;
      text-align: center;
      margin-top: 1.40845vh;
      margin-bottom: 0px;
    }
    
    h2{
      font-family: SohneBreitHalbfettKursiv;
      font-size: 5.625vw;
      line-height: 6.875vw;
      letter-spacing: 0em;
      text-align: center;
      color: $content-on-color;
      margin: 0px;
      text-transform: uppercase;
    }
  
    .session-expired-p{
      font-family: SohneBuch;
      font-size: 5vw;
      line-height: 6.565vw;
      letter-spacing: 0em;
      text-align: center;
      color: $content-on-color;
      margin-top: 2.8169vh;
      margin-bottom: 0px;
    }
  }

  .image-container{
    width: 20vw;
    height: 10vw;
    margin-top: 2vh;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}