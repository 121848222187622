@import "./colors";

.box-default {
  border: 1px solid #dcdbdc !important;
  border-radius: 5px;
}

/* BUTTON PRIMARY */
.btn-primary-outline {
  padding: 5px 22px;
  border-radius: 6px;
  color: $primary;
  background-color: transparent;
  border: 1px solid $primary;
  box-shadow: none;
  font-size: 15px;
  font-family: AdminFontMedium;
  cursor: pointer;
}

.btn-primary {
  padding: 5px 22px;
  border-radius: 6px;
  color: rgb(255, 255, 255);
  background-color: $primary;
  border: 1px solid $primary;
  box-shadow: none;
  font-size: 15px;
  font-family: AdminFontMedium;
  cursor: pointer;
}

.btn-primary:disabled, .btn-primary-outline:disabled {
  background-color: #e7bcc0;
  color: $primary;
  border-color: #e7bcc0;
  cursor: default;
}

.MuiButton-containedPrimary {
  padding: 8px 22px !important;
  border-radius: 6px !important;
  color: rgb(255, 255, 255) !important;
  background-color: $background-secondary !important;
  box-shadow: rgb(9 30 66 / 12%) 0px 1px 3px !important;
}
.MuiButton-outlinedPrimary {
  padding: 8px 22px !important;
  border-radius: 6px !important;
  color: $content-primary !important;
  background-color: transparent !important;
  box-shadow: rgb(9 30 66 / 12%) 0px 1px 3px !important;
  border-color: $border-disabled !important;
}

.MuiButton-outlinedPrimary:hover {
  color: rgb(255, 255, 255) !important;
  background-color: $background-secondary !important;
  border-color: transparent !important;
}

/* BUTTON SECONDARY */
.MuiButton-textSecondary {
  padding: 8px 22px !important;
  border-radius: 6px !important;
  color: rgba(0, 0, 0, 0.87) !important;
  background-color: transparent !important;
}

/* checked */
.MuiSwitch-colorPrimary.Mui-checked {
  color: $content-primary !important;
}

.MuiSwitch-track {
  background: $background-secondary !important;
}

/* Link */
.MuiTypography-colorPrimary {
  color: $content-primary !important;
}

/* Menu */
.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background: $background-secondary-opacity !important;
}

.MuiListItem-root.MuiListItem-gutters:active {
  color: #000 !important;
}

.p-20 {
  padding: 20px;
}

.p-0 {
  padding: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mt-2 {
  margin-top: 20px !important;
}

.mr-5px {
  margin-right: 5px;
}

.ml-2 {
  margin-left: 20px;
}

.mr-2 {
  margin-right: 20px;
}

.mb-2 {
  margin-bottom: 20px !important;
}

.mb-12{
  margin-bottom: 12px !important;
}

.w-100 {
  width: 100% !important;
}

.MuiTableCell-head {
  font-size: 12px !important;
}

.alert-success {
  background-color: $background-tertiary;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 6px !important;
  color: #fff;
}

.alert-danger {
  background-color: $background-quaternary;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 6px !important;
  color: #000;
}

a.MuiButtonBase-root:hover {
  color: #000 !important;
}

/* DASH background */
.css-fxbtpg{
  background-color: #f5f4f9 !important;
}
