/* 01 Content */
$content-primary: #F00214;
$content-secondary: #141414;
$content-tertiary: #AFAFAF;
$content-on-color: #FDFDFD;
$content-primary-disabled: #FE7A84;
$content-secondary-disabled: #C8C8C8;
$content-primary-hover: #5A0108;

/* 02 Background */
$background-primary: #FDFDFD;
$background-secondary: #F00214;
$background-secondary-disabled: #FE7A84;
$background-tertiary: #141414;
$background-quaternary: #F6921E;
$background-gradient: linear-gradient(32.24deg, #F6921E 10.94%, #F00214 87.35%);

/* 03 Border */
$border-on-color: #FFFFFF;
$border-primary: #141414;
$border-disabled: #F00214;