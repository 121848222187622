.onboarding{
  .onboarding-element{    
    .skip{
      width: auto;
      align-self: end;
      // padding: 6.25%;
      padding: 3.52113vh 6.25vw;

      a{
        text-decoration: none;
        font-family: SohneBreitDreiviertelfett;
        font-size: 5vw;
        line-height: 6.25vw;
        color: #F00214;
      }
    }
  
    .slider-container{
      flex-grow: 1;
      display: flex;
      align-items: center;
      padding-bottom: 3.52113vh;
      overflow: hidden auto;

      .swiper{
        max-width: 980px;
        width: 86.25vw;
        max-height: 142.5vw;
        height: 100%;
        overflow: visible;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;    
    
        .swiper-wrapper{
          max-height: 128.75vw;

          .swiper-slide-active{
            background-color: #E30613;
      
          }
      
          .swiper-slide-next, .swiper-slide-prev{
            width: 100% !important;
            height: 100%;
            background-color: #FE7A84;
          }

          .swiper-slide:not(.swiper-slide-active, .swiper-slide-next, .swiper-slide-prev){
            box-shadow: none;
          }
      
          .swiper-slide{
            border-radius: 7.5vw;
            box-shadow: 0px 15vw 15vw 0px rgba(0, 33, 68, 0.10);
      
            .swiper-slide-shadow{
              background: none;
            }
      
            .card-container{
              width: calc(100% - 10vw);
              height: calc(100% - 10vw);
              padding: 5vw;
              background-color: inherit;
              border-radius: inherit;

              .image-container{
                width: 100%;
                height: 50%;

                .animation-onboarding{
                  width: 100%;
                  height: 100%;
                }
                
                img{
                  object-fit: contain;
                  object-position: bottom;
                  width: 100%;
                  height: 100%;
                }
              }

              .text-container{
                width: 100%;
                height: calc(50% - 5vw);
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;
                padding-top: 5vw;

                .title{
                  font-family: SohneBreitHalbfettKursiv;
                  font-size: 7.5vw;
                  line-height: 8.75vw;
                  letter-spacing: 0em;
                  text-align: center;
                  color: $content-on-color;
                  margin: 0px;
                }

                .text{
                  font-family: SohneBuch;
                  font-size: 5vw;
                  line-height: 6.5625vw;
                  letter-spacing: 0em;
                  text-align: center;
                  color: $content-on-color;
                  margin-top: 5vw;
                  margin-bottom: 0px;
                }
              }
            }
          }
        }
    
        .swiper-pagination{
          position: relative;
          bottom: 0px;
    
          .swiper-pagination-bullet{
            background: black;
            margin: 0px 0.78125vw;
            width: 2.5vw;
            height: 2.5vw;
          }
    
          .swiper-pagination-bullet-active{
            background: #E30613;
          }
        }
      }
    }
  }
}