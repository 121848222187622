@font-face {
    font-family: AdminFontRegular;
    src: url('../../assets/fonts/Admin/Raleway-Regular.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontItalic;
    src: url('../../assets/fonts/Admin/Raleway-Italic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontLight;
    src: url('../../assets/fonts/Admin/Raleway-Light.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontLightItalic;
    src: url('../../assets/fonts/Admin/Raleway-LightItalic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontExtraLight;
    src: url('../../assets/fonts/Admin/Raleway-ExtraLight.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontExtraLightItalic;
    src: url('../../assets/fonts/Admin/Raleway-ExtraLightItalic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontThin;
    src: url('../../assets/fonts/Admin/Raleway-Thin.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontThinItalic;
    src: url('../../assets/fonts/Admin/Raleway-ThinItalic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontMedium;
    src: url('../../assets/fonts/Admin/Raleway-Medium.ttf') format('truetype');
}

@font-face {
    font-family: AdminFontMediumItalic;
    src: url('../../assets/fonts/Admin/Raleway-MediumItalic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontBold;
    src: url('../../assets/fonts/Admin/Raleway-Bold.ttf') format('truetype');
}

@font-face {
    font-family: AdminFontBoldItalic;
    src: url('../../assets/fonts/Admin/Raleway-BoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontExtraBold;
    src: url('../../assets/fonts/Admin/Raleway-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: AdminFontExtraBoldItalic;
    src: url('../../assets/fonts/Admin/Raleway-ExtraBoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: AdminFontBlack;
    src: url('../../assets/fonts/Admin/Raleway-Black.ttf') format('truetype');
}

@font-face {
    font-family: AdminFontBlackItalic;
    src: url('../../assets/fonts/Admin/Raleway-BlackItalic.ttf') format('truetype');
}


/* FONTS WEBFRONT*/


/* Queens */
@font-face {
    font-family: QueensLightIt;
    src: url('../../assets/fonts/User/QUEENS/Queens-LightIt.otf') format('truetype');
}
@font-face {
    font-family: QueensCondensedBoldIt;
    src: url('../../assets/fonts/User/QUEENS/QueensCondensed-BoldIt.otf') format('truetype');
}
@font-face {
    font-family: QueensCondensedBoldItalic;
    src: url('../../assets/fonts/User/QUEENS/QueensCondensed-BoldItalic.ttf') format('truetype');
}
@font-face {
    font-family: QueensCondensedMediumItalic;
    src: url('../../assets/fonts/User/QUEENS/QueensCondensed-MediumItalic.ttf') format('truetype');
}

/* Sohne */
@font-face {
    font-family: SohneBuch;
    src: url('../../assets/fonts/User/SOHNE/Sohne-Buch.otf') format('truetype');
}
@font-face {
    font-family: SohneBuchKursiv;
    src: url('../../assets/fonts/User/SOHNE/Sohne-BuchKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneDreiviertelfett;
    src: url('../../assets/fonts/User/SOHNE/Sohne-Dreiviertelfett.otf') format('truetype');
}
@font-face {
    font-family: SohneDreiviertelfettKursiv;
    src: url('../../assets/fonts/User/SOHNE/Sohne-DreiviertelfettKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneExtrafett;
    src: url('../../assets/fonts/User/SOHNE/Sohne-Extrafett.otf') format('truetype');
}
@font-face {
    font-family: SohneExtrafettKursiv;
    src: url('../../assets/fonts/User/SOHNE/Sohne-ExtrafettKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneExtraleicht;
    src: url('../../assets/fonts/User/SOHNE/Sohne-Extraleicht.otf') format('truetype');
}
@font-face {
    font-family: SohneExtraleichtKursiv;
    src: url('../../assets/fonts/User/SOHNE/Sohne-ExtraleichtKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneFett;
    src: url('../../assets/fonts/User/SOHNE/Sohne-Fett.otf') format('truetype');
}
@font-face {
    font-family: SohneFettKursiv;
    src: url('../../assets/fonts/User/SOHNE/Sohne-FettKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneHalbfett;
    src: url('../../assets/fonts/User/SOHNE/Sohne-Halbfett.otf') format('truetype');
}
@font-face {
    font-family: SohneHalbfettKursiv;
    src: url('../../assets/fonts/User/SOHNE/Sohne-HalbfettKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneKraftig;
    src: url('../../assets/fonts/User/SOHNE/Sohne-Kraftig.otf') format('truetype');
}
@font-face {
    font-family: SohneKraftigKursiv;
    src: url('../../assets/fonts/User/SOHNE/Sohne-KraftigKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneLeicht;
    src: url('../../assets/fonts/User/SOHNE/Sohne-Leicht.otf') format('truetype');
}
@font-face {
    font-family: SohneLeichtKursiv;
    src: url('../../assets/fonts/User/SOHNE/Sohne-LeichtKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitBuch;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-Buch.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitBuchKursiv;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-BuchKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitDreiviertelfett;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-Dreiviertelfett.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitDreiviertelfettKursiv;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-DreiviertelfettKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitExtrafett;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-Extrafett.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitExtrafettKursiv;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-ExtrafettKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitExtraleicht;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-Extraleicht.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitExtraleichtKursiv;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-ExtraleichtKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitFett;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-Fett.otf') format('truetype');
}
@font-face {
    font-family: SohneBreit-FettKursiv;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-FettKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitFettKursiv;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-FettKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitHalbfett;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-Halbfett.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitHalbfettKursiv;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-HalbfettKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitKraftig;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-Kraftig.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitKraftigKursiv;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-KraftigKursiv.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitLeicht;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-Leicht.otf') format('truetype');
}
@font-face {
    font-family: SohneBreitLeichtKursiv;
    src: url('../../assets/fonts/User/SOHNE/SohneBreit-LeichtKursiv.otf') format('truetype');
}
