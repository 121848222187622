.disclaimer{
  .disclaimer-container{
    width: calc(100% - 48px);
    height: calc(100% - 66px);
    padding: 33px 24px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;

    h1{
      font-family: SohneBreitHalbfettKursiv;
      font-size: 10vw;
      line-height: 11.25vw;
      text-align: center;
      color: $content-primary;
      margin-top: 0px;
      margin-bottom: 16px;
    }

    p{
      font-family: SohneBuch;
      font-size: 5vw;
      line-height: 6.5625vw;
      text-align: center;
      color: $content-secondary;
      margin: 0px;
    }

    .button-block button{
      font-size: 5vw;
      line-height: 6.25vw;
    }
  }
}

@media(min-width: 981px){
  .disclaimer{
    .disclaimer-container{
      h1{
        font-size: 98px;
        line-height: 101px;
        margin-top: 0px;
        margin-bottom: 16px;
      }
  
      p{
        font-size: 49px;
        line-height: 53px;
        margin: 0px;
      }

      .button-block button{
        font-size: 49px;
        line-height: 53px;
      }
    }
  }
}