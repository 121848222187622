.qr-screen{
  background-color: $primary;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;

  .top-container{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: calc(100% - 34vw);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    .text-container{
      width: calc(50% - 7.7vw);
      padding-right: 9.375vw;

      p{
        margin: 0px;
        font-family: SohneBuch;
        color: #FDFDFD;
        font-size: 3.75vw;
        line-height: 4.53125vw;
        letter-spacing: 0em;
        text-align: left;
      }
    }

    .qr-container{
      width: 30.938vw;
      height: 30.938vw;

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  .logo-container{
    position: absolute;
    right: 3.125vw;
    bottom: 3.999999vh;
    width: 12.5vw;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}