.bookmark{
  .bookmark-navbar{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 48px);
    padding: 16px 24px;
    left: 0px;
    top: 0px;
    background: $background-primary;
    box-shadow: 0px 2px 6px rgba(0, 33, 68, 0.1);

    h1{
      margin: 0px;
      font-family: SohneHalbfettKursiv;
      font-size: 5.625vw;
      line-height: 6.875vw;
      color: $content-primary;
    }

    a.close-button{
      padding: 3.125vw;
      width: max-content;

      .close-icon{
        width: 3.75vw;
        height: 3.75vw;
      }
    }
  }
  
  .bookmark-content{
    padding: 8.3916vw 24px 30px;

    .bookmark-element{
      background: #FDFDFD;
      box-shadow: 0px 2px 6px rgba(0, 33, 68, 0.1);
      border-radius: 1.25vw;
      padding: 2.8125vw 2.5vw;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: stretch;
      justify-content: space-between;
      margin-bottom: 2.5vw;

      .bookmark-image{
        width: 25.8vw;
        height: 27.5vw;

        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .bookmark-text{
        width: calc(100% - (22.5vw + 3.75vw));
        padding: 0px 12px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: space-between;

        h2{
          font-family: QueensCondensedBoldIt;
          font-size: 5vw;
          line-height: 5.625vw;
          color: $content-secondary;
          margin: 0px;
        }

        p{
          font-family: SohneBuch;
          font-size: 3.4375vw;
          line-height: 4.375vw;
          color: $content-tertiary;
          margin: 0px;
        }
      }

      .bookmark-save{
        display: flex;
        align-items: flex-end;

        .save-icon{
          width: 3.75vw;
        }
      }
    }
  }

  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.bookmark-empty{
  .bookmark-navbar{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 48px);
    padding: 16px 24px;
    left: 0px;
    top: 0px;
    background: $background-secondary;

    h1{
      margin: 0px;
      font-family: SohneHalbfettKursiv;
      font-size: 6.25vw;
      line-height: 7.8125vw;
      color: $content-on-color;
    }

    a.close-button{
      padding: 3.125vw;
      width: max-content;

      .close-icon{
        width: 3.75vw;
        height: 3.75vw;
      }
    }
  }

  .bookmark-content{
    padding: 0px 24px 40px;
    position: relative;
    height: calc(100vh - (36.7832vw + 40px));
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: space-between;

    .bookmark-text{
      width: 100%;
      margin: auto;
      height: max-content;

      h2{
        font-family: SohneBreitHalbfettKursiv;
        font-size: 7.5vw;
        line-height: 8.75vw;
        text-align: center;
        color: $content-on-color;
        margin: 0px 0px 24px 0px;
      }

      p{
        font-family: SohneBuch;
        font-size: 5vw;
        line-height: 6.5625vw;
        text-align: center;
        color: $content-on-color;
        margin: 0px;
      }
    }

    .bookmark-button{
      width: 100%;

      button{
        font-family: SohneBreitDreiviertelfett;
        font-size: 5vw;
        line-height: 6.25vw;
        text-align: Center;
        padding: 10px;
        background-color: $background-primary;
        border: none;
        color: #F00214;
        width: 100%;
      }
    }
  }

  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media(min-width: 981px){
  .bookmark{
    .bookmark-navbar{
      h1{
        font-size: 61px;
        line-height: 66px;
      }
  
      a.close-button{
        padding: 30px;
  
        .close-icon{
          width: 36px;
          height: 36px;
        }
      }
    }
    
    .bookmark-content{
      padding: 82px 24px 30px;

      .bookmark-element{
        padding: 27px 24px;
        margin-bottom: 24px;

        .bookmark-image{
          width: 220.72px;
          height: 269.77px;
        }
  
        .bookmark-text{
          width: calc(100% - (220.72px + 36px));
  
          h2{
            font-size: 40px;
            line-height: 42px;
          }
  
          p{
            font-size: 33px;
            line-height: 36px;
          }
        }
  
        .bookmark-save{
          .save-icon{
            width: 36px;
          }
        }
      }
    }
  }

  .bookmark-empty{
    .bookmark-navbar{
      h1{
        font-size: 61px;
        line-height: 66px;
      }
  
      a.close-button{
        padding: 30px;
  
        .close-icon{
          width: 36px;
          height: 36px;
        }
      }
    }
  
    .bookmark-content{
      height: calc(100vh - 225px);
      padding: 0px 24px 22px;
  
      .bookmark-text{
        h2{
          font-size: 73px;
          line-height: 77px;
        }
  
        p{
          font-size: 49px;
          line-height: 54px;
        }
      }
  
      .bookmark-button{
        button{
          font-size: 49px;
          line-height: 53px;
        }
      }
    }
  }
}