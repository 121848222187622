/** {
    outline:1px solid orange
}
*/

.d-none{
    display:none;
}


.linkDesktop{

    display: none;
}

.menu span {
    cursor:pointer;
}



.menu a, .menu span{
    color: #000; 
    text-decoration: none; 
}


.menu a.link-active, .menu span.link-active
{
    text-decoration: line-through !important;
}


.cajaSubMenu 
{
    position:relative;
    left: 0;
    top: 0;
    background-color: #fff;
    padding-left: 0;
    padding-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    width: 100%;
    padding-top: 0px;
    padding: 0px 0px 0px 4px !important;
}


.submenu
{
    height: 0;
    overflow: hidden;
    opacity: 0;
    transition: all 0.5s ease;
    width: 100%;
    background-color: #fff;
    display: none;
}

.submenu span
{
    margin-top: 10px;
    margin-bottom:5px;
}

.submenu.open{

    opacity: 1;
    height: auto;
    transform: translateY(0);
    transition: all 1s ease;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    width: 100%;
    padding: 4px 0px;
}


.linkMovile{
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}

.linkDesktop{
    display: none;
}

.langSelectorDesktop
{
    display: none;
}

.langSelectorMovile
{
    display: block;
}


div.closeIcon{
    float: right;
}

.navbar-web{
    padding: 15px;

    .menu-container{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        
        .logo-container{
            width: 33.333333333333333%;
            
            .logo-link{
                // max-width: 91.19px;
                max-width: 28.5vw;
                width: 100%;
                height: 100%;

                .logo-image{
                    object-fit: contain;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .left-block{
            width: 33.333333333333333%;

            .langSelectorMovile{
                // max-width: 11.7vw;
                // max-width: 37.44px;
                font-size: 3.33334vw;
                // font-size: 10.66px;
    
                .MuiSelect-select{
                    padding: 0px;
                }
                svg{
                    right: -5vw;
                    // right: -10px;
                    width: 6.155vw;
                }
            }
        }

        .right-block{
            width: 33.333333333333333%;

            .tickets-container{
                max-width: 23.073vw;
                // max-width: 73.83px;
    
                a{
                    font-size: 3.33333vw;
                    // font-size: 10.66px;
                    padding: 1.285vw 2.96vw;
                    // padding: 4.112px 9.472px;
                }
            }
        }
        
    }

    .menu-web-links{
        font-size: 3.33334vw;
        // font-size: 10.66px;
        margin-right: 5px;
    }

    .submenu{

        a{
            font-size: 4.61538vw;
        }
    }
}

.lang-item{
    font-family: SohneKraftig;
    font-size: 3.33334vw;
    // font-size: 10.66px;
}

@media(min-width: 1440px){
    .navbar-web{
        padding: 15px 24px !important;

        .menu-container{
            .logo-link{
                max-width: 115.21px !important;
            }
    
            .right-block{¡
                .langSelectorDesktop{¡
                    .MuiSelect-select{
                        padding: 0px 24px 0px 0px !important;
                    }

                    svg{
                        right: 0px !important;
                        width: 24px !important;
                    }
                }
            }

            .tickets-container{
                a{
                    font-size: 15px !important;
                    padding: 5.5px 15.5px !important;
                }
            }
        }
    
        .linkDesktop{
            .menu-web-links{
                font-size: 14px !important;
                margin-right: 35px !important;
            }
        }
    }
    
    .lang-item{
        font-size: 18px !important;
    }

    .cajaSubMenu{
        .submenu{
            width: calc(100% - 38px);
            padding: 0px 38px 4px 0px;
    
            span{
                margin-right: 35px;

                a{
                    font-size: 15px !important;
                }
            }
    
        }
    }
}

@media(min-width: 1024px){
   
    .open{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    .linkMovile{

        display: none;
    }

    .linkDesktop{

        display: block;
    }

    .langSelectorMovile
    {
        display: none !important;
    }

    .langSelectorDesktop
    {
        display: inline-block;
    }

    // .closeIcon{
    //     display:none
    // }
}


@media(min-width: 768px){

    
    .open{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
    }

    
    .linkMovile{

        display: none;
    }

    .linkDesktop{

        display: block;
    }

    .langSelectorMovile
    {
        display: none !important;
    }

    .langSelectorDesktop
    {
        display: inline-block;
    }

    // .closeIcon{
    //     display:none
    // }

    .navbar-web{
        padding: 15px 20px;

        .menu-container{
            .logo-container{
                width: 20%;
            }
    
            .left-block{
                width: 40%;
            }
    
            .right-block{
                width: 40%;
                max-width: none !important;
    
                .langSelectorDesktop{
                    .MuiSelect-select{
                        padding: 0px 1.5vw 0px 0px;
                    }

                    svg{
                        right: -0.2vw;
                        width: 2.011vw;
                    }
                }

                .tickets-container{
                    max-width: none;
                }
            }
            
        }
    
        .linkDesktop{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: center;
            justify-content: flex-start;

            .menu-web-links{
                font-size: 1.08878vw;
                margin-right: 2.0938vw;
            }
        }
    }
    
    .lang-item{
        font-size: 1.08878vw;
    }

    .cajaSubMenu{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .submenu{
            position: absolute;
            display: flex !important;
            flex-direction: row !important;
            flex-wrap: wrap !important;
            align-items: flex-start;
            justify-content: flex-start;
            max-width: none;
            width: calc(100% - 38px);
            padding: 0px 38px 4px 0px;
    
            span{
                margin-right: 2.34506vw;

                a{
                    font-size: 1.17253vw;
                }
            }
    
        }
    }
}

@media(min-width: 768px) and (max-width: 1439px){
    .navbar-web{
        .menu-container{
            .logo-container{
                .logo-link{
                    max-width: 9.65vw;
                }
            }

            .right-block{
                .tickets-container{
                    a{
                        font-size: 1.25628vw;
                        padding: 0.5vw 1.248vw !important;
                    }
                }
            }
            
        }
    }
}