#app { height: 100% }
html,
body {
  position: relative;
  height: 100%;
}
.stories{
  .swiper {
    width: 100%;
    // height: 100%;
    .swiper-wrapper{
      height: 100% !important;
    }
  }

  .swiper-button-prev, .swiper-button-next{
    height: 100%;
    top: 22px;
    bottom: 0px;
    width: 17.19vw;
  }
  
  .swiper-button-prev{
    left: 0px;
  }

  .swiper-button-next{
    right: 0px;
  }

  .swiper-slide {
    // text-align: center;
    font-size: 18px;
    background: #fff;
  
    /* Center slide text vertically */
    // display: -webkit-box;
    // display: -ms-flexbox;
    // display: -webkit-flex;
    // display: flex;
    // -webkit-box-pack: center;
    // -ms-flex-pack: center;
    // -webkit-justify-content: center;
    // justify-content: center;
    // -webkit-box-align: center;
    // -ms-flex-align: center;
    // -webkit-align-items: center;
    // align-items: center;
  
    min-height: 100vh;
    height: 100%;
    overflow-y: auto;
  
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  
  .swiper-pagination{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: calc(100% - 48px);
    margin: auto;
    right: 0;
    left: 0;
    top: 21px !important;

    .swiper-pagination-bullet{
      flex-grow: 1;
    }
  }

  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    top: 10px;
    bottom: auto;
    padding: 0px 24px;
  }
  
  .swiper-pagination-bullet{
    width: calc(100%/7 - 1*1em - 2*1px);
    border-radius: 0px;
    height: 4px;
    background: #C8C8C8;
    opacity: 1;
  }

  .swiper-pagination-bullet-active{
    background: #E30613;
  }
  
  :root {
    --swiper-theme-color: #E30613;
  }
  
  /* LINEA SUPERIOR */
  .swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    padding: 0px;
  }
}

@media(min-width: 981px){
  .stories{
    .swiper-button-prev, .swiper-button-next{
      width: 168px !important;
    }
  }
}

.swiper-button-prev {
  color: transparent !important;
}

.swiper-button-next {
  color: transparent !important;
}
