.language{
  .language-list{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: max-content;
    padding-bottom: 57px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;

    button{
        text-decoration: none;
        border: none;
        background: none;
        
        h2, span{
            font-family: QueensCondensedBoldIt;
            font-size: 18.75vw;
            line-height: 20vw;
            text-align: center;
            color: $content-on-color;
            margin-top: 0px;
            margin-bottom: 5vw;
            display: block;
        }
    }
  }

  .logo{
    position: absolute;
    // bottom: 17.8125vw;
    bottom: 57px;
    left: 0;
    right: 0;
    margin: auto;
    width: max-content;
    
    img{
        width: 20vw;
        object-fit: cover;
    }
  }
}

@media(min-width: 981px){
    .language{
        .language-list{
          button{  
              h2, span{
                  font-size: 183px;
                  line-height: 187px;
                  margin-bottom: 49px;
              }
          }
        }
      
        .logo{
          img{
              width: 196px;
          }
        }
    }
}