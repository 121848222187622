.game-result{
  .game-navbar{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 15vw);
    padding: 5vw 7.5vw;
    left: 0px;
    top: 0px;
    background: $background-primary;

    h1{
      margin: 0px;
      font-family: SohneBreitHalbfettKursiv;
      font-size: 5.625vw;
      line-height: 6.875vw;
      text-transform: uppercase;
    }

    a.close-button{
      padding: 3.125vw;
      width: max-content;

      .close-icon{
        width: 3.75vw;
        height: 3.75vw;
      }
    }
  }
    
  .game-result-container{
    padding: 0px 7.5vw 7.04225vh;
    height: calc(100% - 32.5vw);
    flex-grow: 1;

    .game-info{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      padding: 0px;
       
      .info-card{
        padding: 0px 7.1875vw;
        width: 100%;
        border-radius: 2.5vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-y: hidden;

        .info-card-scroll{
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          overflow-y: auto;
        }

        .profile-container{
          margin-bottom: 2.8169vh;

          p{
            margin: 0px;
            font-family: SohneBreitHalbfettKursiv;
            text-align: center;
            font-size: 4.375vw;
            line-height: 4.8125vw;
            letter-spacing: -0.28px;
            text-transform: uppercase;
          }
        }
        
        .img-container{
          width: 100%;
          height: auto;
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          flex-wrap: nowrap;

          .icon{
            width: 100%;
            height: 25.94vw;
            object-fit: contain;
            object-position: center;
          }
        }

        .text-container{          
          h2{
            font-family: QueensCondensedBoldIt;
            font-size: 10vw;
            line-height: 10vw;
            letter-spacing: 0em;
            text-align: center;
            margin-top: 2.8169vh;
            margin-bottom: 1.40845vh;
            width: 68.75vw;
            margin-left: auto;
            margin-right: auto;
          }

          .counter-result{
            text-align: center;
            font-family: SohneBuch;
            font-size: 5vw;
            line-height: 6.875vw;
            margin-top: 1.40845vh;
            margin-bottom: 0px;
            width: 68.75vw;
            margin-left: auto;
            margin-right: auto;
          }

          p:not(.counter-result){
            font-family: SohneBuch;
            font-size: 5.625vw;
            line-height: 6.875vw;
            letter-spacing: 0em;
            text-align: center;
            margin-top: 2.8169vh;
            margin-bottom: 0px;
            width: 100%;
          }
        }
      }

      .button-block{
        padding: 3.125vw 0px;
        font-family: SohneBreitDreiviertelfett;
        font-size: 5vw;
        line-height: 6.25vw;
        letter-spacing: 0em;
        text-align: center;
        border: none;
        margin-top: 2.8169vh;
        width: 100%;
      }
    }
  }
}