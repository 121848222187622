.mobile-message{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100vw;
    height: 100vh;
    background-color: #E30613;
    z-index: 4;

    .mobile-message-modal{
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        background-color: white;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 385px;
        max-height: 190px;
        border-radius: 8px;

        p{
            margin: 10px 0px 0px 0px;
            text-align: center;
            font-family: SohneBuch;
            font-size: 16px;
            max-width: 255px;
        }
    }
}