.mapa{
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .map-navbar{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 15vw);
    padding: 5vw 7.5vw;
    left: 0px;
    top: 0px;
    background: $background-primary;

    h1{
      margin: 0px;
      font-family: SohneBreitHalbfettKursiv;
      font-size: 5.625vw;
      line-height: 6.875vw;
      color: $content-primary;
      text-transform: uppercase;
    }

    a.close-button{
      padding: 3.125vw;
      width: max-content;

      .close-icon{
        width: 3.75vw;
        height: 3.75vw;
      }
    }
  }

  .map-plantas{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 15.625vw);
    margin: auto;

    button{
      width: 40.94vw;
      height: 10vw;
      font-family: SohneBreitKraftig;
      font-size: 3.75vw;
      line-height: 4.375vw;
      text-align: center;
      border: none;
    }
    button.activated{
      background: $background-secondary;
      color: #FDFDFD;
    }
    button:not(.activated){
      background: #FDFDFD;
      color: $content-primary;
      border: 0.3125vw solid $content-primary;
    }
  }

  .app {
    width:100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 5vw 0px 0px;
    height: 116.25vw;
    position: relative;

    div{
      height: inherit;
      width: inherit;
    }

  }
  .app::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg, transparent 0 95%, #FFF 100% 100%);
    pointer-events: none;
  }
  
  .map-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    position: absolute;
    background: $background-secondary;
    transform: scale(1);
  
    font-family: SohneBreitBuch;
    font-size: 3.75vw;
    line-height: 4.375vw;
    color: #FDFDFD;
    border: none;
  }

  .map-button:not(.sala-text) {
    width: 7.5vw;
    height: 7.5vw;
    padding: 0px;
  }

  .map-button.sala-text{
    padding: 2.5vw;
    max-width: 23.44vw;
  }

  .map-button:hover, .map-button:focus{
    background: $background-secondary;
  }

  .pinch-block{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 10vw;
    background-size: auto 100%;
    background-position: bottom;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 25%);
    
    .pinch-icon{
      width: 7.695vw;
      height: 6.97vw;
    }

    p{
      margin-bottom: 0px;
      margin-top: 5.3907vw;
      color: $content-primary;
      font-family: SohneBuch;
      font-size: 3.4375vw;
      line-height: 4.375vw;
    }
  }

  .edifici-button{
    width: 12.5vw;
    height: 12.5vw;
    position: absolute;
    bottom: 32.19vw;
    right: 7.5vw;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap;

    .edifici-icon{
      width: 6.875vw;
      height: 5.875vw;
    }
  }
}

@media(min-width: 981px){
  .mapa{
    .map-navbar{
      width: 832px;
      padding: 49px 73px;
  
      h1{
        font-size: 55px;
        line-height: 59px;
      }
  
      a.close-button{
        padding: 30px;
  
        .close-icon{
          width: 36px;
          height: 36px;
        }
      }
    }
  
    .map-plantas{
      width: 832px;
  
      button{
        width: 392px;
        height: 98px;
        font-size: 36px;
        line-height: 43px;
      }
      button.oulined{
        border: 3px solid $content-primary;
      }
    }
  
    .app {
      padding: 49px 0px 0px;
      height: 1189px;
    }
    
    .map-button {
      padding: 24px;
      width: 73px;
      height: 73px;
      font-size: 36px;
      line-height: 42px;
    }
  
    .pinch-block{
      padding-bottom: 98px;
      
      .pinch-icon{
        width: 71px;
        height: 64px;
      }
  
      p{
        margin-top: 52px;
        font-size: 33px;
        line-height: 42px;
      }
    }
  
    .edifici-button{
      width: 122px;
      height: 122px;
      bottom: 316px;
      right: 74px;
  
      .edifici-icon{
        width: 46px;
        height: 49px;
      }
    }
  }  
}