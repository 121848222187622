#admin-content{
    padding: 32px 10px 0px 10px;

    .box-title-line {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        margin-top: 10px;
        padding-right: 0px;
    }

    .MuiGrid-container{
        padding: 0px 8px;
    }

    h1{
        margin-top: 0px;
        margin-bottom: 10px;
    }

    #admin-menu-left{
        h3{
            margin-top: 0px;
            font-size: 16px;
        }

        .menu-links{
            padding-bottom: 7px;

            a{
                font-family: AdminFontRegular;
                color: $font-dark;
                text-decoration: none;
                font-size: 14px;
                padding-top: 5px;
                padding-left: 12px;
                border-left: 1px solid $border-grey-light;
                padding-bottom: 7px;
            }

            .active {
                color: $primary;
                font-family: AdminFontBold;
                border-left: 2px solid $primary;
            }
        }
    }

    .dash-tabs{
        button.Mui-selected{
            color: $primary;
        }
        .css-1aquho2-MuiTabs-indicator{
            background-color: $primary;
        }
    }

}

// Provisional
#admin-content > div > div.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.css-1idn90j-MuiGrid-root > div.MuiGrid-root.MuiGrid-container.MuiGrid-spacing-xs-1.css-17wqeb1-MuiGrid-root > div:nth-child(1) > div > a{
    margin-top: 0px !important;
}

#prov-form{
    font-size: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    font-family: AdminFontRegular;

    h3{
        font-size: 16px;
    }
    p{
        font-family: AdminFontBold;
        color: $font-grey;

        span{
            font-family: AdminFontMedium;
        }
    }
}

.prov-form-select{
    font-size: 16px;
    padding: 4px 8px;
    border-radius: 6px;
    border: 1px solid $border-grey-light;
    font-family: AdminFontMedium;
}

.prov-button-div{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

}

.MuiButton-containedPrimary{
    background-color: $primary !important;
}

.pagination{
    color: $font-grey;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    justify-content: space-between;
    font-size: 14px;
    font-family: AdminFontRegular;

    p{
        margin: 0px;
    }

    div{
        display: flex;
        align-items: center;
    }

    .pagination-buttons{
        button{
            font-family: AdminFontMedium;
            color: $font-primary;
            border: 1px solid $border-grey;
            background-color: $background-light;
            border-radius: 6px;
            padding: 4px 7px;
            cursor: pointer;
        }
        button:disabled{
            color: $font-grey;
            border: 1px solid $border-grey;
            background-color: $background-light;
            cursor: auto;
        }
    }
}

button.css-f24hxp-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.force-style{
    background-color: #ffffff !important;
}

button.css-f24hxp-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root.force-style:hover{
    background-color: #F00214 !important;
}

.boxScrollbar::-webkit-scrollbar {
    width: 4px;
}
.boxScrollbar::-webkit-scrollbar-track {
    background: rgba(50,50,50,0.0);
}
.boxScrollbar::-webkit-scrollbar-track:hover {
    width:15px;
    background: rgba(85,85,85,0.15);
}
.boxScrollbar::-webkit-scrollbar-thumb {
    width:15px;
    background: rgba(136,136,136,0.7);
}
.boxScrollbar::-webkit-scrollbar-thumb:hover {
    width:15px;
    background: rgba(85,85,85,0.7);
}