@keyframes fade-in {
  0% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes fade-in-background {
  0% { background-color: transparent }
  100% { background-color: $background-tertiary }
}

.component-playerAudio {
  display: flex;
  background-color: $background-tertiary;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;

  .audio-player-button-background{
    width: 9vw;
    height: 9vw;
    background-color: $background-secondary;
    border-radius: 100%;
    position: absolute;
    left: 24px;
    z-index: 1;
  }

  .rhap_container{
    z-index: 2;
  }

  .rhap_additional-controls, .rhap_volume-controls, .rhap_current-time, .rhap_total-time {
    display: none !important;
  }

  .rhap_progress-bar{
    background-color: $background-primary;

    .rhap_progress-indicator{
      background: $background-secondary;
      margin-left: 0px;
    }
  }

  .rhap_main{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;

    .rhap_controls-section{
      flex: initial;
      margin-top: 0px;

      .rhap_main-controls{
        button{
          margin: 0px;

          svg{
            width: 100%;
            height: auto;

            path{
              fill: #ffffff;
            }
          }
        }

      }
      
      .rhap_main-controls button:focus, .rhap_main-controls button:hover{
        opacity: 1;
      }
    }

    .rhap_progress-container{
      margin-left: 18px;
      margin-right: 0px;
    }
  }
}

.component-playerAudio.admin-audio {
  .audio-player-button-background{
    display: none;
  }

  .rhap_container{
    padding: 10px 37px 10px 15px;
  }

  .rhap_main{
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    .rhap_controls-section{
      .rhap_main-controls{
        button{
          width: 50px;
          height: 50px;
        }
      }
    }

    .rhap_progress-container{
      margin-left: -3px;
      .rhap_progress-indicator{
        width: 20px;
        height: 20px;
        margin-top: 0px;
        margin-left: 0px !important;
      }
    }
  }

  .audio-close-icon{
    display: none;
  }
}

@media(min-width: 768px){
  .component-playerAudio{
    .audio-player-button-background{
      left: 32px;
      width: 69px;
      height: 69px;
    }
  }
}

@media(min-width: 981px){
  .component-playerAudio {
    .rhap_container{
      padding: 17px 60px 17px 15px;
    }
    .rhap_main{
      .rhap_controls-section{
        .rhap_main-controls{
          button{
            width: 125px;
            height: 125px;
          }
        }
      }
  
      .rhap_progress-container{
        margin-left: -11px;
      }
    }

    .rhap_progress-bar{  
      height: 7px;
      .rhap_progress-indicator{
        width: 37px;
        height: 37px;
        margin-top: -7px;
      }
    }
  }
}

@media(max-width: 980px){
  .component-playerAudio {
    .rhap_container{
      padding-top: calc(26px - 2.125vw);
      padding-bottom: calc(26px - 2.125vw);
      padding-right: 6.12vw;
      padding-left: calc(24px - 2.125vw);
    }
    .rhap_main{
      .rhap_controls-section{
        .rhap_main-controls{
          button{
            width: 12.75vw;
            height: 12.75vw;
          }
        }
      }
  
      .rhap_progress-container{
        margin-left: -1.04vw;
      }
    }

    .rhap_progress-bar{  
      height: 0.625vw;
      .rhap_progress-indicator{
        width: 3.75vw;
        height: 3.75vw;
        margin-top: -0.5vw;
      }
    }
  }
}

@media(min-width: 415px) and (max-width: 595px){
  .component-playerAudio{
    .rhap_progress-bar{
      .rhap_progress-indicator{
        margin-top: 0vw !important;
      }
    }
  }
}

@media(max-width: 414px){
  .component-playerAudio{
    .rhap_progress-bar{
      .rhap_progress-indicator{
        margin-top: 0.8vw !important;
      }
    }
  }
}