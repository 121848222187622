body{
    margin: 0px !important;
    font-family: AdminFontMedium;
}
#admin-header{
    a{
        text-decoration: none;
        color: $font-dark;
    }
    .active {
        color: #ffffff !important;
        background-color: $primary;
        border-radius: 100px;
    }
    a:not(.active):hover{
        background-color: $background-grey-light;
        border-radius: 100px;
    }

    .admin-menu-right {
        margin-left: 15px;
        float: right;
    }

    .admin-menu-left {
        margin-right: 15px;
    }

    .admin-menu-line {
        border-left: 1px solid #dadada;
    }

    .first-menu, .second-menu{
        padding: 0px 10px;
        border-bottom: 1px solid $border-grey-light;
        display: flex;
        align-items: center;
    }

    .first-menu{
        background-color: $background-grey-light;
        height: 44px;

        .MuiGrid-item{
            padding: 0px 8px;
        }

        .MuiGrid-item:last-child{
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        #logo{
            max-height: 32px;
        }
    }

    .second-menu{
        height: 40px;

        a{
            padding: 3px 10px;
            font-size: 14px;
        }
        .MuiGrid-item{
            padding: 8px 0px;
        }
        .MuiGrid-item:first-child{
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: nowrap;
        }
        .MuiGrid-item:last-child{
            text-align: end;
        }
    }

    .menu-account{
        display: flex;
        justify-content: flex-end;

        .MuiButtonBase-root{
            padding: 0px;
            .MuiListItemIcon-root{
                display: flex;
                justify-content: flex-end;
            }
        }
    }

}

#fade-menu{
    .menu-name{
        padding: 6px 16px 10px;
        border-bottom: 1px solid $border-grey-light;
    }

    li{
        color: $primary;
        font-size: 14px;
    }

    li:hover{
        color: inherit;
    }
}

a:active{
    color: inherit;
}

.MuiGrid-container{
    max-width: 1690px;
    margin: auto;
}

h1, h2, h3, h4, h5, h6{
    font-family: AdminFontBold;
}

h1{
    font-size: 28px;
}

.font-medium{
    font-family: AdminFontMedium !important;
}

.font-bold{
    font-family: AdminFontBold !important;
}

#admin-menu-phone {
    a{
        text-decoration: none;
        color: $font-dark;
        padding: 5px 10px;
    }

    .active {
        color: #ffffff;
        background-color: $primary;
        border-radius: 100px;
    }

    .admin-menu-right {
        margin-top: 15px;
    }

    .admin-menu-left {
        margin-top: 15px;
    }

    .admin-menu-line {
        display: none;
    }

    .menu-links {
        margin-top: 15px;
    }
}

.admin-menu-btn {
    background: transparent;
    border: none;
    font-family: AdminFontMedium;
    font-size: 17px;
    padding: 10px 0px;
}

.first-menu {
    background-color: $background-grey-light;
}
