.splash{
  @keyframes splashIn {
    0% {
      scale: 0;
    }
    100% {
      scale: 1;
    }
  }

  .splash-container{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100vw;
    height: 100vh;

    img, video{
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
}

@media(min-width: 981px){
  .splash{
    .splash-container{
      width: 515px;
      height: 243px;
    }
  }
}
