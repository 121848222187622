
.footer{
    background-color: #F5F5F5;
    padding: 15px 10px;
}

.cajaMovile{
    display: flex;
    background-color: #F5F5F5;
    
    margin-left: 0px !important;
    margin-top: 0px !important;

    p{
        margin-bottom: 0px !important;
    }

    .title{
        font-size: 12px;
        line-height: 14px;
    }

    svg{
        width: 29px;
        height: 29px;
    }

    a{
        color: #000000;
    }
}

.cajaTabDesk{
    display: none !important;
    background-color: #F5F5F5;
    
    margin-left: 0px !important;
    margin-top: 0px !important;

}

.cajaMovile .title, .cajaTabDesk .title{
    font-family: SohneBreitFettKursiv;
    border-bottom: 2px solid #000;
    margin-top: 0px;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.cajaMovile .text, .cajaTabDesk .text{
    font-family: SohneBuch;
   
}


.text p:first-child {margin-top:7px;}
.text p{margin-top:0px; margin-bottom: 0px;}
.text p:last-child {margin-bottom:7px;}

.cajaMovile .text p a, .cajaTabDesk .text p a{
   text-decoration: none;
}


.cajaTabDesk .fcol2 p:first-child{
    margin-top: 0;
}


.cajaMovile .titleRRSS, .cajaTabDesk .titleRRSS{
    font-family: SohneBreitFettKursiv;
    border-top: 2px solid #000;
    border-bottom: 2px solid #000;
    padding-top: 5px;
    padding-bottom: 5px;
}

.cajaTabDesk .cajaIcons{

    min-height: 75px;
}




.ficon{
    color: #000;
    text-decoration: none;
    margin-right: 5px;
}


@media(min-width: 768px){
    .cajaMovile{
        display: none !important;
    }

    .cajaTabDesk{
        // display: flex !important;
        display: grid !important;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 5px;

        .title, .titleRRSS{
            font-size: 12px;
            line-height: 14px;
        }

        .titleRRSS{
            margin-top: 10px;
            margin-bottom: 5px;
            padding: 5px 0px;
        }
    
        svg{
            width: 30px;
            height: 30px;
        }

        .MuiGrid-root{
            padding-left: 0px !important;
            padding-top: 0px !important;
        }

        .info-practica-container{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: nowrap;

            .info-practica{
                width: 100%;

                p{
                    margin-bottom: 0px !important;
                }
            }
        }

        .text-legal{
            font-size: 14px;
            line-height: 16px;
        }

        a{
            color: #000000;
        }
    }
    
}

@media(min-width: 1024px){
    .footer{
        background-color: #F5F5F5;
        padding: 20px 25px;
    }

    .cajaMovile{
        display: none;
    }

    .cajaTabDesk > .MuiGrid-root:not(:first-child){
        margin-left: 20px;
    }
    .cajaTabDesk > .MuiGrid-root:nth-child(2){
        width: 40% !important;
    }
    .cajaTabDesk > .MuiGrid-root{
        width: 20% !important;
    }
    .cajaTabDesk{
        display: flex !important;

        .title{
            font-size: 14px;
            line-height: 16px;
        }

        .info-practica-container{
            flex-direction: row;
            
            .info-practica{
                width: 50%;
                padding-right: 5px;

                p{
                    margin-bottom: 0px !important;
                }
            }
        }
    }
    
}