
.stories.stories-iframe-style::-webkit-scrollbar {
  width: 0em;
}
  
.stories.stories-iframe-style::-webkit-scrollbar-track {
  box-shadow: none;
}
  
.stories.stories-iframe-style::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0px solid transparent;
}

.stories.stories-iframe-style{
  .hide-scroll::-webkit-scrollbar {
    width: 0em;
  }
   
  .hide-scroll::-webkit-scrollbar-track {
    box-shadow: none;
  }
   
  .hide-scroll::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: 0px solid transparent;
  }
}

.stories{
  
  .player-container{
    width: auto;
    height: auto;
    
    .react-player{
      padding-bottom: 56.25%;
      position: relative;

      .react-player__shadow{
        display: none;

        .react-player__play-icon{
          display: none;
        }
      }
    }

    .react-player > div, .react-player > video{
      position: absolute;
    }
  }

  // Animación vertical de la flecha
  .outstanding-image-block{
    @keyframes updown {
      0% {
        transform: translateY(-80%);
      }
    
      50% {
        transform: translateY(0%);
      }
    
      100% {
        transform: translateY(-80%);
      }
    }

    .key-board-arrow-animation{
      animation: updown 2s ease infinite;
    }
    
  }
  
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .text-block, .text-block-html{
    p{
      margin: 0px;
      strong{
        font-family: SohneHalbfett;
      }
    }
  }
}

@media(min-width: 981px){
  .stories{
    .name-closeicon-container{
      h2{
        font-size: 62px;
        line-height: 67px;
      }
  
      a.close-button{
        padding: 31px;
  
        .close-icon{
          width: 37px;
          height: 37px;
        }
      }
    }

    .cover-story-block{
      .audio-button{
        padding: 27px;
  
        .headphones-icon{
          width: 41px;
          height: 41px;
        }
  
      }
  
      div.title-container{
        padding: 16px 24px 71px;

        .story-cover-title{
          p{
            font-family: 'SohneBuch';
            font-size: 74px;
            line-height: 78px;
            margin: 0px;
            margin-bottom: 0px !important;

            em{
              font-family: QueensCondensedBoldItalic;
              font-style: normal;
            }
          }
        }
  
        p{
          font-size: 34px;
          line-height: 37px;
          margin-bottom: 13px !important;
        }
  
        div.deslizar-text{
          font-size: 37px;
          line-height: 45px;
  
          .keyboard-arrow-down-icon{
            margin-right: 17px;
            width: 40px;
          }
        }
      }
    }
  

    .second-story-block{
  
      div.deslizar-text{
        // max-width: 368px;
        padding: 16px 24px 78px;
  
        p{
          font-size: 46px;
          line-height: 49px;
          margin-bottom: 53px !important;
        }
        
        .keyboard-arrow-down-icon{
          width: 80px;
        }
      }
    }

    .play-icon{
      width: 169px;
      height: 169px;
    }

    .audio-close-icon{
      width: 44px;
      padding-right: 66px;
    }
  }
}

@media(max-width: 980px){
  .stories{
    .name-closeicon-container{
      h2{
        font-size: 5.625vw;
        line-height: 6.875vw;
      }
  
      a.close-button{
        padding: 3.125vw;
  
        .close-icon{
          width: 3.75vw;
          height: 3.75vw;
        }
      }
    }
    .cover-story-block{
      .image-filter{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgb(0 0 0 / 50%) 0%, rgb(0 0 0 / 0%) 50%);
      }

      .audio-button{
        padding: 2.66vw;
  
        .headphones-icon{
          width: 5.315vw;
          height: 5.315vw;
        }
      }
  
      div.title-container{
        padding: 16px 24px 7.30995vw;

        .story-cover-title{
          margin-bottom: 1.25vw !important;
          
          p{
            font-family: 'SohneBuch';
            font-size: 7.5vw;
            line-height: 8.75vw;
            margin: 0px;
            margin-bottom: 0px !important;

            em{
              font-family: QueensCondensedBoldItalic;
              font-style: normal;
            }
          }
        }
  
        p{
          font-size: 3.4375vw;
          line-height: 4.375vw;
          margin-bottom: 1.25vw !important;
        }
  
        div.deslizar-text{
          font-size: 3.75vw;
          line-height: 5vw;
  
          .keyboard-arrow-down-icon{
            width: 4.065vw;
            margin-right: 1.5625vw;
          }
        }
      }
    }
  
    .second-story-block{
      .image-filter{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgb(0 0 0 / 50%) 0%, rgb(0 0 0 / 0%) 50%);
      }

      div.deslizar-text{
        // max-width: 32.565vw;
        padding: 16px 24px 7.8125vw;
  
        p{
          font-size: 3.75vw;
          line-height: 5vw;
          margin-bottom: 5.3125vw !important;;
        }
  
        .keyboard-arrow-down-icon{
          width: 4vw;
        }
      }
    }

    .play-icon{
      width: 17.19vw;
      height: 17.19vw;
    }

    .audio-close-icon{
      width: 4.45vw;
      padding-right: 6.66vw;
    }

    .player-container{
      .react-player{
        .react-player__shadow{
          .react-player__play-icon{
            border-width: 7.5vw 0px 7.5vw 15.7vw !important;
          }
        }
      }
    }
  }
}

.stories-instructions{
  background: #000000B8;
  backdrop-filter: blur(1.5625vw);
  width: calc(100vw - 15vw);
  height: calc(100% - 64px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 4;
  padding: 4.22535vh 7.5vw 7.04225vh 7.5vw;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .instructions-container{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    flex: 1;

    .instructions-element{
      width: 64.69vw;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;  
      
      img{
        height: 10.565vh;
        max-height: 18.75vw;
        object-fit: contain;
      }
  
      p{
        font-family: SohneBreitHalbfett;
        font-size: clamp(4.6875vw, 4.6875vw, 2.64085vh);
        line-height: clamp(5.625vw, 5.625vw, 3.16902vh);
        letter-spacing: 0em;
        text-align: center;
        color: #FFFFFF;
        margin-top: 0.880282vh;
        margin-bottom: 0px;
      }
    }

    .instructions-element:first-child{
      margin-bottom: 4.4014vh;
    }
  }

  .instructions-button-container{
    height: 12.5vw;

    button{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: inherit;
      padding: 2.11268vh 1.5625vw;
      background: #E30613;
      font-family: SohneBreitDreiviertelfett;
      font-size: clamp(5vw, 5vw, 2.8169vh);
      line-height: clamp(6.25vw, 6.25vw, 3.52112vh);
      letter-spacing: 0em;
      text-align: center;
      color: #FDFDFD;
      border: none;
    }
  }
}