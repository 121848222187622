@import "./colors";

h3.fields-title {
  padding: 0px 0px 20px 0px;
  margin: 0px 0px 0px 0px;
  border-bottom: 1px solid #e8ebef;
  letter-spacing: 1px;
  font-size: 23px;
}

h3.fields-title-1 {
  padding: 30px 0px 20px 0px;
  margin: 0px 0px 0px 0px;
  border-bottom: 1px solid #e8ebef;
  border-top: 1px solid #e8ebef;
  letter-spacing: 1px;
  font-size: 23px;
}

.text-center {
  text-align: center;
}

.w-600 {
  width: 600px;
}

.box-center {
  margin-left: auto;
  margin-right: auto;
  display: block;
}
