.search{
  .search-navbar{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 48px);
    padding: 16px 24px;
    left: 0px;
    top: 0px;
    background: $background-primary;

    h1{
      margin: 0px;
      font-family: SohneBreitHalbfettKursiv;
      font-size: 5.625vw;
      line-height: 6.875vw;
      color: $content-primary;
      text-transform: uppercase;
    }

    a.close-button{
      padding: 3.125vw;
      width: max-content;

      .close-icon{
        width: 3.75vw;
        height: 3.75vw;
      }
    }
  }
  
  .search-content{
    padding: 5.3125vw 24px 17px;

    .input-container{
      padding-bottom: 6.25vw;

      .search-block{
        background: $background-primary;
        margin-bottom: 5vw;
        margin-left: 0px;
        margin-right: 0px;

        input{
          font-family: SohneBuch;
          font-size: 5vw;
          line-height: 6.5625vw;
          box-shadow: 0px 2px 12px rgba(0, 33, 68, 0.1);
          border-radius: 2px;
          padding: 2.8125vw 1.5625vw 2.8125vw 11.25vw;
          width: 100%;
        }

        input::placeholder{
          font-family: SohneBuch;
          font-size: 5vw;
          line-height: 6.5625vw;
          color: #C8C8C8;
        }

        .MuiInputBase-root{
          width: 100%;
        }

        .search-icon-wrapper{
          padding: 0px 8px;

          
          .search-icon{
            width: 5vw;
            height: 5vw;
          }
        }
      }

      .order-block{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;

        span{
          font-family: SohneBreitKraftig;
          font-size: 4.375vw;
          line-height: 5vw;
          text-align: center;
          letter-spacing: -0.09375vw;
          color: #141414;
          display: block;
        }
        
        .MuiFormControl-root{
          min-width: 45vw;
          margin: 0px;
  
          .keyboard-arrow-down-icon-2{
            z-index: 1;
            display: flex;
            align-items: center;
            flex-direction: row;
            flex-wrap: nowrap;
            width: 4.065vw;
            height: 2.19vw;
            padding-right: 2.8125vw;
            padding-top: 1vw;
          }
  
          .MuiSelect-select{
            font-family: SohneBreitKraftig;
            font-size: 3.75vw;
            line-height: 4.375vw;
            color: $content-on-color;
            z-index: 1;
            padding: 3.4375vw 1.5625vw 1.5625vw 2.5vw;
            max-width: 45vw;
          }
  
          fieldset{
            border: none;
            background-color: $background-secondary;
            border-radius: 0;
            padding: 0px;
          }
        }
      }
    }

    .results-container{
      .results-text{
        padding-bottom: 2.5vw;

        span{
          font-family: SohneBuch;
          font-size: 3.4375vw;
          line-height: 4.375vw;
          color: $content-secondary;
          display: block;
        }
      }

      .results-element{
        padding: 2.8125vw 5vw 2.8125vw 2.5vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: stretch;
        justify-content: space-between;
        background: #FDFDFD;
        box-shadow: 0px 2px 6px rgba(0, 33, 68, 0.1);
        border-radius: 1.25vw;
        margin-bottom: 2.5vw;
  
        .results-image{
          width: 26.36vw;
          height: 27.5vw;
  
          img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }
  
        .results-text{
          width: calc(100% - (22.5vw + 3.75vw));
          padding: 0px 12px;
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: stretch;
          justify-content: space-between;

          .artwork-title{
            em{
              font-style: normal;
            }
          }
  
          .artwork-title, .artwork-title p{
            font-family: QueensCondensedBoldIt;
            font-size: 5vw;
            line-height: 5.625vw;
            color: $content-secondary;
            margin: 0px;
          }
  
          p{
            font-family: SohneBuch;
            font-size: 3.4375vw;
            line-height: 4.375vw;
            color: $content-tertiary;
            margin: 0px;
          }
        }
      }
    }
  }

  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.MuiPaper-root{
  ul.MuiList-root{
    padding: 0px;
    
    li.search-li{
      background-color: $background-secondary;
      font-family: SohneBreitKraftig;
      font-size: 3.75vw;
      line-height: 4.375vw;
      color: $content-on-color;
      padding: 1.875vw 5vw;
    }

    li.search-li:first-child{
      padding-top: 3.125vw;
    }

    li.search-li:last-child{
      padding-bottom: 3.125vw;
    }
  }
}

@media(min-width: 981px){
  .search{
    .search-navbar{
      h1{
        font-size: 55px;
        line-height: 59px;
      }
  
      a.close-button{
        padding: 30px;
  
        .close-icon{
          width: 36px;
          height: 36px;
        }
      }
    }
    
    .search-content{
      padding: 52px 24px 17px;

      .input-container{
        padding-bottom: 61px;
  
        .search-block{
          margin-bottom: 49px;
  
          input{
            font-size: 49px;
            line-height: 64px;
            padding: 27px 15px 27px 110px;
          }
  
          input::placeholder{
            font-size: 49px;
            line-height: 64px;
          }
  
          .search-icon-wrapper{
            .search-icon{
              width: 49px;
              height: 49px;
            }
          }
        }
  
        .order-block{
          span{
            font-size: 42px;
            line-height: 49px;
            letter-spacing: -0.9px;
          }
          
          .MuiFormControl-root{
            min-width: 441px;
    
            .keyboard-arrow-down-icon-2{
              width: 39px;
              height: 21px;
              padding-right: 27px;
              padding-top: 8px;
            }
    
            .MuiSelect-select{
              font-size: 36px;
              line-height: 42px;
              padding: 33px 14px 14px 24px;
              max-width: 441px;
            }
          }
        }
      }
  
      .results-container{
        .results-text{
          padding-bottom: 24px;
  
          span{
            font-size: 33px;
            line-height: 42px;
          }
        }
  
        .results-element{
          padding: 27px 24px 40px;
          margin-bottom: 24px;
    
          .results-image{
            width: 239px;
            height: 269px;
          }
    
          .results-text{
            width: calc(100% - (214px + 36px));
    
            .artwork-title, .artwork-title p{
              font-size: 49px;
              line-height: 55px;
            }
    
            p{
              font-size: 33px;
              line-height: 42px;
            }
          }
        }
      }
    }
  }

  .MuiPaper-root{
    ul.MuiList-root{
      li.search-li{
        font-size: 36px;
        line-height: 42px;
        padding: 18px 49px;
      }
  
      li.search-li:first-child{
        padding-top: 30px;
      }
  
      li.search-li:last-child{
        padding-bottom: 30px;
      }
    }
  }
}