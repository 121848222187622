.not-found-page{
  z-index: 3;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: #E30613;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  
  h2{
    margin: 0px;
    font-family: SohneBreitDreiviertelfettKursiv;
    font-size: 10vw;
    line-height: 10.9375vw;
    letter-spacing: 0em;
    text-align: center;
    color: $content-on-color;
  }

  h1{
    margin-bottom: 0px;
    margin-top: 4.22535vh;
    font-family: QueensCondensedBoldItalic;
    font-size: 41.875vw;
    line-height: 29vw;
    text-align: center;
    color: $content-on-color;
  }
}

@media(min-width: 1024px){
  .not-found-page{
    h2{
      font-size: 102.4px;
      line-height: 112px;
    }
  
    h1{
      margin-top: 45.6338px;
      font-size: 428.8px;
      line-height: 296.95px;
    }
  }
}