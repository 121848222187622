.mapa-results{
  p, em{
    font-style: normal !important;
  }

  .mapa-results-element{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: stretch;

    .map-navbar{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      width: calc(100% - 15vw);
      padding: 5vw 7.5vw 10.3125vw;
      left: 0px;
      top: 0px;
      background: $background-primary;
  
      h1{
        margin: 0px;
        font-family: SohneBreitHalbfettKursiv;
        font-size: 5.625vw;
        line-height: 6.875vw;
        color: $content-primary;
        text-transform: uppercase;
      }
  
      a.close-button{
        padding: 3.125vw;
        width: max-content;
  
        .close-icon{
          width: 3.75vw;
          height: 3.75vw;
        }
      }
    }
  
    .container{
      width: calc(100vw - 15vw);
      margin: auto;
  
      .sala-title{
        background: #F00214;
        box-shadow: 0px 2px 12px rgba(0, 33, 68, 0.1);
        margin-bottom: 4.0625vw;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 2.1875vw 4.0625vw;
  
        h3{
          font-family: SohneBreitBuch;
          font-size: 4.375vw;
          line-height: 5vw;
          color: #FDFDFD;
          margin: 0px;
        }
        .close-icon{
          display: flex;
          width: 3.75vw;
          height: 3.75vw;
        }
      }
      .result-list{
        margin-bottom: 8.125vw;

        .results-element{
          padding: 2.8125vw 5vw 2.8125vw 2.5vw;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: stretch;
          justify-content: space-between;
          background: #FDFDFD;
          box-shadow: 0px 2px 6px rgba(0, 33, 68, 0.1);
          border-radius: 1.25vw;
          margin-bottom: 2.5vw;
    
          .results-image{
            width: 26.36vw;
            height: 27.5vw;
    
            img{
              width: 100%;
              height: 100%;
              object-fit: cover;
              object-position: center;
            }
          }
    
          .results-text{
            width: calc(100% - (22.5vw + 3.75vw));
            padding: 0px 12px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: stretch;
            justify-content: space-between;
    
            .artwork-title, .artwork-title p{
              font-family: QueensCondensedBoldIt;
              font-size: 5vw;
              line-height: 5.625vw;
              color: $content-secondary;
              margin: 0px;
            }
    
            p{
              font-family: SohneBuch;
              font-size: 3.4375vw;
              line-height: 4.375vw;
              color: $content-tertiary;
              margin: 0px;
            }
          }
        }
      }
    }
    .salas-select{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 15.625vw;
      margin: 0px 7.5vw;
  
      a{
        font-family: SohneBreitDreiviertelfett;
        font-size: 5vw;
        line-height: 6.25vw;
        display: flex;
        align-items: center;
        text-align: center;
        color: $content-primary;
  
        .keyboard-arrow-left-icon, .keyboard-arrow-right-icon{
          height: 4.065vw;
          width: 2.19vw;
          display: flex;      
        }
        .keyboard-arrow-left-icon{
          margin-right: 3.91vw;
        }
        .keyboard-arrow-right-icon{
          margin-left: 3.91vw;
        }
      }
    }
  }
}

@media(min-width: 981px){
  .mapa-results{
    .mapa-results-element{
      .map-navbar{
        width: 832px;
        padding: 49px 73px;
    
        h1{
          font-size: 55px;
          line-height: 59px;
        }
    
        a.close-button{
          padding: 30px;
    
          .close-icon{
            width: 36px;
            height: 36px;
          }
        }
      }
    
      .container{
        width: 832px;
    
        .sala-title{
          margin-bottom: 39px;
          padding: 21px 39px;
    
          h3{
            font-size: 42px;
            line-height: 44px;
          }
          .close-icon{
            width: 36px;
            height: 36px;
          }
        }
        .result-list{
          margin-bottom: 79px;
  
          .results-element{
            padding: 27px 24px 40px;
            margin-bottom: 24px;
      
            .results-image{
              width: 239px;
              height: 269px;
            }
      
            .results-text{
              width: calc(100% - (214px + 36px));
      
              .artwork-title, .artwork-title p{
                font-size: 49px;
                line-height: 55px;
              }
      
              p{
                font-size: 33px;
                line-height: 42px;
              }
            }
          }
        }
      }
      .salas-select{
        padding-bottom: 153px;
        margin: 0px 73px;
    
        a{
          font-family: SohneBreitDreiviertelfett;
          font-size: 49px;
          line-height: 53px;
    
          .keyboard-arrow-left-icon, .keyboard-arrow-right-icon{
            height: 39px;
            width: 21px;  
          }
          .keyboard-arrow-left-icon{
            margin-right: 38px;
          }
          .keyboard-arrow-right-icon{
            margin-left: 38px;
          }
        }
      }
    }
  }
}