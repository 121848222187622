.web-page{
    background-color: #FFFFFF;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    overflow: hidden;
    padding: 0px 5px;
    z-index: 0;
    position: relative;

    .blocks-full-width{
        width: 100vw;
        margin: 0px calc(-5px);
    }

    // Bloc de Capçalera
    .header-block{
        width: calc(100vw - 20px);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        background-color: #F5F5F5;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding: 10px;

        .text-container{
            height: 80.665%;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            max-width: 354px;

            h1, h2, h3, h4, h5, h6, p{
                color: #FFF;
                text-align: center;
                font-family: SohneBreitDreiviertelfettKursiv;
                font-size: 37px;
                line-height: 34px;
                text-transform: uppercase;
                margin: 0px;
            }
        }

        .button-bottom{
            height: 19.355%;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-end;
            align-items: center;
            max-height: 156px;
            
            div.entradas-container{
                height: 53.21%;
            }
            div.scroll-container{
                height: 46.8%;
            }
            .block-link{
                color: #FFF;
                text-align: center;
                font-family: SohneBuch;
                font-size: 20px;
                font-style: normal;
                line-height: normal;
                padding: 5px 18px;
                border-radius: 80.5px;
                background: #F00;
                border: none;
                text-decoration: none;
            }

            img{
                max-width: 52.405px;
                max-height: 24.56px;
                object-fit: cover;
            }

        }

        @media(min-width: 768px){
            .text-container{
                max-width: 794px;
    
                h1, h2, h3, h4, h5, h6, p{
                    font-size: 86px;
                    line-height: 82px;
                }
            }
    
            .button-bottom{
                max-height: 164px;

                .block-link{
                    font-size: 20px;
                    line-height: 126%;
                    padding: 8.4px 24px;
                }
            }
        }

        @media(min-width: 1024px){
            .button-bottom{
                max-height: 171px;
            }
        }
    }

    .header-block.without-button{
        .text-container{
            height: 94.736%;
        }

        .button-bottom{
            height: 5.264%;
            max-height: 73px;

            div.scroll-container{
                height: 100%;
            }
        }

        @media(min-width: 768px){
            .button-bottom{
                max-height: 95px;
            }
        }

        @media(min-width: 1024px){
            .button-bottom{
                max-height: 171px;
            }
        }
    }

    // Bloc de Visita
    .visit-block{
        padding: 20px 0px;
        width: calc(100vw - 20px);
        border-top: 1px solid #000000;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        margin: auto;

        .grid-box{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: center;
            width: 100%;

            .image-container{
                width: 100%;
                height: 94.5vw;
                
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
    
            .text-container{
                width: 100%;
                margin-top: 20px;
    
                p, h1, h2, h3, h4, h5, h6{
                    margin: 0px;
                }
            }
    
            .icons-container{
                width: 100%;
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: stretch;
    
                .row-container{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
    
                    .icon-container{
                        width: 29px;
                        height: 29px;
                        margin-right: 10px;
                        align-self: flex-start;
    
                        img{
                            object-fit: contain;
                            object-position: center;
                        }
                    }
                    p{
                        margin: 0px;
                        color: #000;
                        font-family: SohneLeicht;
                        font-size: 13px;
                        line-height: 126%;
                    }
                }
                .row-container:not(:last-child){
                    margin-bottom: 19px;
                }
            }
    
            .block-link{
                margin-top: 26px;
                text-align: center;
                font-family: SohneBuch;
                font-size: 18px;
                line-height: 126%;
                letter-spacing: 0.18px;
                text-decoration: none;
            }
            .red-button{
                color: #FFF;
                background: #F00;
                border: none;
                border-radius: 34.36px;
                padding: 9px 19px;
            }
            .red-link-container{
                background: #F00;
                border: none;
                border-radius: 34.36px;
                padding: 9px 19px;

                .red-link{
                    color: #FFF;
                }
            }
            .white-link-container{
                border: 1px solid #000000;
                background-color: #FFF;
                border-radius: 34.36px;
                padding: 9px 19px;
            }
            .white-button{
                color: #000;
            }

            .red-button ~ .white-link-container, .red-link-container ~ .white-link-container{
                margin-top: 8px;
            }

            .icons-container, .text-container{
                margin-bottom: 26px;
            }
        }
    }

    @media(min-width: 768px){
        .visit-block{
            max-width: 904px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-auto-rows: 0fr;
            grid-column-gap: 26px;
            min-height: 440px;
            padding: 35px 0px;

            .grid-box{
                height: 100%;
                align-items: flex-start;
                justify-content: flex-start;

                .image-container{
                    height: 100%;
                }
        
                .text-container{
                    margin-top: 0px;
                }
        
                .icons-container{

                    .icon-container{
                        margin-right: 14px !important;
                    }

                    .row-container{
                        p{
                            font-size: 18px;
                        }
                    }
                }
        
                .block-link{
                    margin-top: 20px;
                }

                .icons-container, .text-container{
                    margin-bottom: 20px;
                }
            }

            .grid-box.text-box{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                flex-wrap: nowrap;
            }
        }
    }

    @media(min-width: 1024px){
        .visit-block{
            max-width: 996px;
            min-height: 484px;
            padding: 37px 0px;

            .grid-box{
                .icons-container{
                    margin-top: 25px;

                    p{
                        font-size: 20px !important;
                        line-height: 130% !important;
                    }
                }

                .block-link{
                    font-size: 20px;
                    line-height: 130%;
                    letter-spacing: 0.2px;
                }
            }
        }
    }

    // Bloc de Carrousel
    .carousel-block{
        width: calc(100vw - 20px);
        margin: 25px auto;

        .title-container{
            p{
                margin: 0px;
                color: #000;
                text-align: center;
                font-family: SohneBreitDreiviertelfettKursiv;
                font-size: 38px;
                line-height: 95%;
                text-transform: uppercase;
            }
        }

        .without-title{
            height: 114.5vw;
            margin-top: 25px;
        }

        .with-title{
            width: calc(100% - 10px);
            height: 58.7vw;
            margin-top: 10px;
            overflow: initial;

            .swiper-slide{
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.25);
            }
        }

        .swiper{
            .swiper-wrapper{
                .swiper-slide{
                    position: relative;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                    }

                    p{
                        margin: 0px;
                        position: absolute;
                        top: 10px;
                        left: 15px;
                        color: #FFF;
                        font-family: SohneLeichtKursiv;
                        font-size: 18px;
                        line-height: 124%;
                    }
                }
            }

            .swiper-button-prev, .swiper-button-next{
                color: white !important;
                scale: 0.8;
            }

            .swiper-pagination{
                .swiper-pagination-bullet{
                    width: 10px;
                    height: 10px;
                    background-color: #FFFFFF;
                }
            }
        }

        .subtitle-container{
            margin-top: 7px;

            p{
                margin: 0px;
                color: #000;
                text-align: center;
                font-family: SohneLeicht;
                font-size: 18px;
                line-height: 124%;
            }
        }

        .button-container{
            margin-top: 7px;
            display: flex;
            justify-content: center;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
            padding: 7.5px 14px;
            border-radius: 20px;
            border: 1px solid #000;
            background-color: #FFF;
            width: max-content;
            margin-left: auto;
            margin-right: auto;

            a{
                color: #000;
                text-align: center;
                font-family: SohneKraftig;
                font-size: 18px;
                line-height: 126%;
                text-decoration: none;
            }
        }
    }

    @media(min-width: 768px){
        .carousel-block{
            .title-container{
                p{
                    font-size: 52px;
                    line-height: 52px;
                    letter-spacing: 0em;
                }
            }
    
            .without-title{
                height: 44.7vw;
                width: 75.3vw;
            }
    
            .with-title{
                height: 20.3vw;
            }
        }
    }

    @media(min-width: 1024px){
        .carousel-block{
            .without-title{
                max-width: 996px;
                max-height: 590px;
            }
    
            .subtitle-container{
                p{
                    font-size: 20px;
                    line-height: 130%;
                }
            }
    
            .button-container{
                a{
                    border-radius: 25px;
                    font-size: 20px;
                    line-height: 26px;
                    letter-spacing: 0.01em;
                }
            }
        }
    }

    // Bloc d'Imatge i Text (Uno: Imagen con título en medio; Dos: Imagen pantalla completa; Tres: Imagen de 1/2 pantalla; Cuatro: Imagen de 1/3 pantalla)
    .image-text-block{
        // width: calc(100vw - 20px);

        .block-link{
            color: #FFF;
            text-align: center;
            font-family: SohneBuch;
            font-size: 15px;
            font-style: normal;
            line-height: normal;
            padding: 5px 18px;
            border-radius: 80.5px;
            background: #F00;
            border: none;
            text-decoration: none;
            margin-top: 15px;
            align-self: center;
        }
    }

    .plantilla-uno{
        padding: 25px 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin: auto;
        
        .image-title-container{
            position: relative;
            overflow: hidden;
            width: 100%;
            max-height: 114.5vw;

            .image-container{
                width: 100%;
                height: 100%;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    max-height: 114.5vw;
                }
            }

            .title{
                width: max-content;
                max-width: 95%;
                height: max-content;
                max-height: 114.5vw;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                color: #FFF;
                text-align: center;
                font-family: SohneBreitDreiviertelfettKursiv;
                font-size: 36px;
                line-height: 106%;
                text-transform: uppercase;
            }
        }

        .photo-footer{
            color: #000;
            font-family: SohneBuch;
            font-size: 18px;
            line-height: 124%;
            margin-top: 8.5px;
            margin-bottom: 0px;

            p, h1, h2, h3, h4, h5, h6{
                margin: 0px;
            }
        }
    }

    .plantilla-dos{
        padding: 12.5px 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin: auto;

        .image-title-container{
            width: 100%;
            
            .image-container{
                max-height: 114.5vw;
                
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    max-height: 114.5vw;
                }
            }
        }

        .title{
            width: 100%;
            max-width: 100%;
            color: #000;
            font-family: SohneBreitDreiviertelfettKursiv;
            font-size: 18px;
            line-height: 126%;
            text-transform: uppercase;
            margin-bottom: 0px;
            margin-top: 8px;
        }

        .photo-footer{
            color: #000;
            font-family: SohneBuch;
            font-size: 18px;
            line-height: 126%;
            margin-bottom: 0px;
            margin-top: 8px;

            p, h1, h2, h3, h4, h5, h6{
                margin: 0px;
            }
        }
    }
    
    .plantilla-tres{
        padding: 12.5px 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin: auto;

        .image-title-container{
            width: 100%;
            
            .image-container{
                max-height: 114.5vw;
                
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    max-height: 114.5vw;
                }
            }
        }

        .title{
            width: 100%;
            max-width: 100%;
            margin-bottom: 0px;
            margin-top: 6px;
            color: #000;
            font-family: SohneBreitDreiviertelfettKursiv;
            font-size: 13px;
            line-height: 126%;
            letter-spacing: 0.13px;
            text-transform: uppercase;
        }

        .photo-footer{
            color: #000;
            margin-bottom: 0px;
            margin-top: 6px;
            font-family: SohneBuch;
            font-size: 13px;
            line-height: 126%;

            p, h1, h2, h3, h4, h5, h6{
                margin: 0px;
            }
        }
    }

    .plantilla-cuatro{
        width: calc(48vw - 10px);
        padding: 15px 5px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin: auto;

        .image-title-container{
            width: 100%;
            
            .image-container{
                height: 30.26vw;

                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
        }

        .title{
            width: 100%;
            max-width: 100%;
            margin-bottom: 0px;
            margin-top: 6px;
            color: #000;
            font-family: SohneBreitDreiviertelfettKursiv;
            font-size: 13px;
            line-height: 126%;
            letter-spacing: 0.13px;
            text-transform: uppercase;
        }

        .photo-footer{
            color: #000;
            margin-bottom: 0px;
            margin-top: 6px;
            font-family: SohneBuch;
            font-size: 13px;
            line-height: 126%;
            width: 100%;

            p, h1, h2, h3, h4, h5, h6{
                margin: 0px;
            }
        }
    }

    .image-title-container a {
        align-self: center;
    }

    @media(min-width: 768px){
        .image-text-block{
            .block-link{
                margin-top: 20px;
                font-size: 20px;
                line-height: 126%;
            }
        }

        .plantilla-uno{
            padding: 50px 0px;
            max-width: 779.67px;
            width: 100vw;

            .image-title-container{
                max-height: 508.94px;

                .image-container{
                    max-height: 508.94px;

                    img{
                        max-height: 508.94px;
                    }
                }

    
                .title{
                    max-height: 508.94px;
                    font-size: 52px;
                    line-height: 52px;
                    letter-spacing: 0em;
                }
            }
    
            .photo-footer{
                font-size: 24px;
                line-height: 30px;
                letter-spacing: 0em;
                margin-top: 12px;
            }
        }

        .plantilla-dos{
            max-width: 779.67px;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
    
            .image-title-container{
                .image-container{
                    max-height: 508.94px;

                    img{
                        max-height: 508.94px;
                    }
                }

            }

            .title{
                width: 38%;
                margin-top: 12px;
                font-size: 18px;
                line-height: 23px;
                letter-spacing: 0em;
                text-align: left;

            }
    
            .photo-footer{
                width: 62%;
                margin-top: 12px;
                font-size: 18px;
                line-height: 23px;
                letter-spacing: 0em;
                margin-top: 12px;
            }
        }

        .plantilla-tres{
            width: calc(48vw - 25px);
            max-width: 479.8px;
            margin: 0px 12.5px;
    
            .image-title-container{
                .image-container{
                    max-height: 311.86px;

                    img{
                        max-height: 311.86px;
                    }
                }
            }

            .title{
                font-size: 18px;
                line-height: 126%;
                margin-top: 8px;
            }
    
            .photo-footer{
                font-size: 18px;
                line-height: 126%;
                margin-top: 8px;
            }
        }

        .plantilla-cuatro{
            width: calc(31vw - 10px);
            max-width: 334.02px;
            margin: 0px 10px;
    
            .image-title-container{
                .image-container{
                    height: 20.185vw;
                }
            }

            .title{
                font-size: 18px;
                line-height: 126%;
                margin-top: 8px;
            }
    
            .photo-footer{
                font-size: 18px;
                line-height: 126%;
                margin-top: 8px;
            }
        }
    }

    @media(min-width: 1024px){
        .image-text-block{
            .block-link{
                padding: 8.4px 24px;
            }
        }

        .plantilla-uno{
            max-width: 999px;

            .image-title-container{
                max-height: 655.51px;

                .image-container{
                    max-height: 655.51px;

                    img{
                        max-height: 655.51px;
                    }
                }
    
                .title{
                    max-height: 655.51px;
                    font-size: 52px;
                    line-height: 52px;
                    letter-spacing: 0em;
                }
            }
    
            .photo-footer{
                font-size: 24px;
                line-height: 30px;
                letter-spacing: 0em;
            }
        }

        .plantilla-dos{
            max-width: 999px;
    
            .image-title-container{
                .image-container{
                    max-height: 655.51px;

                    img{
                        max-height: 655.51px;
                    }
                }
            }

            .title{
                font-size: 20px;
                line-height: 25px;
                letter-spacing: 0em;
            }
    
            .photo-footer{ 
                font-size: 20px;
                line-height: 26px;
                letter-spacing: 0em;
            }
        }

        .plantilla-tres{
            max-width: 917.38px;
    
            .image-title-container{
                .image-container{
                    max-height: 602.48px;

                    img{
                        max-height: 602.48px;
                    }
                }
            }

            .title{
                font-size: 20px;
                line-height: 126%;
            }
    
            .photo-footer{
                font-size: 20px;
                line-height: 130%;
            }
        }

        .plantilla-cuatro{
            max-width: 25.87vw;
            margin: 0px 12.5px;
            width: calc(31vw - 25px);
    
            .image-title-container{
                .image-container{
                    height: 17.03vw;
                }
            }

            .title{
                font-size: 20px;
                line-height: 126%;
            }
    
            .photo-footer{
                font-size: 20px;
                line-height: 130%;
            }
        }
    }

    // Bloc de mapa
    .map-block{
        width: 100vw;
        padding: 25px 0px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;

        .title{
            margin-top: 0px;
            margin-bottom: 10px;
            padding: 0px 10px;
            text-align: center;
            color: #000;
            font-family: SohneBreitDreiviertelfettKursiv;
            font-size: 30px;
            line-height: 106%;
            text-transform: uppercase;
        }

        img{
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 74.36vw;
        }

        .text{
            margin-top: 10px;
            margin-bottom: 0px;
            padding: 0px 10px;
            color: #000;
            text-align: center;
            font-family: QueensCondensedBoldItalic;
            font-size: 31px;
            line-height: 107%;
        }
    }

    @media(min-width: 768px){
        .map-block{
            padding: 50px 0px;

            .title{
                margin-bottom: 15px;
                font-size: 37px;
                line-height: 52px;
                letter-spacing: 0em;
                max-width: 815px;
            }
    
            img{
                max-height: 43.803vw;
                height: auto;
            }
    
            .text{
                margin-top: 20px;
                font-size: 24px;
                line-height: 51px;
                letter-spacing: 0em;
                max-width: 815px;
            }
        }
    }

    @media(min-width: 1024px){
        .map-block{
            img{
                max-height: 30.443vw;
            }
        }
    }

    // Bloc de Vídeo
    .video-block{
        padding: 15px 10px;
        width: calc(98vw - 20px);
        position: relative;
        overflow: hidden;
        height: 69vw;

        video{
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    @media(min-width: 768px){
        .video-block{
            padding: 20px 10px;
            width: calc(100vw - 20px);
            position: relative;
            overflow: hidden;
            height: auto;
            max-width: 905px;
            margin: 0px auto;
    
            video{
                position: initial;
                width: 100%;
                height: auto;
                object-fit: initial;
            }
        }
    }

    @media(min-width: 1024px){
        .video-block{
            max-width: 989px;
        }
    }

    // Block d'horaris i tarifes
    .schedule-rate-block{
        width: calc(100vw - 30px);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
        margin: 25px auto;

        .first-column{
            width: 100%;

            .schedule-title, .guide-title{
                color: #000;
                text-align: center;
                font-family: SohneBreitDreiviertelfettKursiv;
                font-size: 30px;
                line-height: 106%;
                text-transform: uppercase;
                margin: 0px;
            }

            .guide-title{
                margin-top: 50px;
            }

            .schedule-text, .guide-text{
                margin-top: 20px;

                p, h1, h2, h3, h4, h5, h6{
                    margin: 0px;
                }
            }
        }
        
        .second-column{
            width: 100%;

            .rate-title{
                color: #000;
                text-align: center;
                font-family: SohneBreitDreiviertelfettKursiv;
                font-size: 30px;
                line-height: 106%;
                text-transform: uppercase;
                margin-bottom: 0px;
                margin-top: 50px;
            }

            .border-top{
                border-top: 1px solid rgba(0, 0, 0, 0.50);
            }

            .rate-container{
                margin-top: 20px;
                
                .subtitle{
                    color: #000;
                    text-align: center;
                    font-family: SohneBreitDreiviertelfettKursiv;
                    font-size: 18px;
                    line-height: 126%;
                    text-transform: uppercase;
                    margin-bottom: 0px;
                    margin-top: 20px;
                }

                .price-container{
                    display: flex;
                    justify-content: space-between;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: flex-start;
                    margin-top: 16px;

                    p, h1, h2, h3, h4, h5, h6{
                        margin: 0px;
                    }

                    div:first-child{
                        width: auto;
                        max-width: 70%;
                    }

                    div:last-child{
                        width: auto;
                        max-width: 30%;
                    }

                }

                .pmt-0 p{
                    margin: 0px;
                }


                .rate-button-container{
                    margin-top: 10px;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-end;
                    align-items: flex-start;

                    .rate-link{
                        color: #FFF;
                        text-align: center;
                        font-family: SohneBuch;
                        font-size: 20px;
                        font-style: normal;
                        line-height: normal;
                        padding: 5px 18px;
                        border-radius: 80.5px;
                        background: #F00;
                        border: none;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    @media(min-width: 768px){
        .schedule-rate-block{
            max-width: 910px;
            margin: 50px auto;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: flex-start;
            justify-content: space-between;
    
            .first-column, .second-column{
                max-width: 351.91px;
                margin: 0px 25px;
                width: calc(48% - 50px);
            }

            .first-column{
                .schedule-title, .guide-title{
                    font-size: 52px;
                    line-height: 52px;
                    letter-spacing: 0em;
                }

                .schedule-text, .guide-text{
                    margin-top: 20px;
                }
            }
    
            .second-column{
                .rate-title{
                    font-size: 52px;
                    line-height: 52px;
                    letter-spacing: 0em;
                    margin-top: 0px;
                }
    
                .rate-container{                    
                    .price-container{
                        margin-top: 7px;

                        div{
                            
                        }
                    }

                    .rate-button-container{
                        margin-top: 20px;
                    }
                }

                .pmt-0 p{
                    margin: 0px;
                }

            }
        }
    }

    @media(min-width: 1024px){
        .schedule-rate-block{
            max-width: 1105px;
    
            .first-column, .second-column{
                max-width: 500px;
                margin: 0px 35px;
                width: calc(48% - 70px);
            }

            .first-column{
                
    
                .schedule-title, .guide-title{
                    font-size: 52px;
                    line-height: 52px;
                    letter-spacing: 0em;
                }
            }
    
            .second-column{
                

                .rate-title{
                    font-size: 52px;
                    line-height: 52px;
                    letter-spacing: 0em;
                }
    
                .rate-container{
                    .subtitle{
                        font-size: 20px;
                        line-height: 25px;
                        letter-spacing: 0em;
                    }

                    .price-container{
                        
                    }
                    
                    .rate-link{
                        font-size: 24px;
                        line-height: 126%;
                        padding: 8.4px 24px;
                    }
                }
            }
        }
    }

    // Bloc de transport
    .transport-block{
        width: calc(100vw - 30px);
        margin: 20px auto;

        .transport-row:first-child{
            margin-top: 5px;
        }

        .transport-row{
            padding: 15px 0px;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: stretch;
            border-top: 1px solid rgba(0, 0, 0, 0.50);

            .transport-first-column, .transport-second-column{
                width: 100%;
            }

            .transport-first-column{
                p{
                    margin: 0px 0px 5px 0px;
                    color: #000;
                    font-family: SohneBreitDreiviertelfettKursiv;
                    font-size: 18px;
                    line-height: 126%;
                    text-transform: uppercase;
                }
            }

            .transport-second-column{
                p{
                    margin: 0px;
                    color: #000;
                    font-family: SohneLeicht;
                    font-size: 18px;
                    line-height: 126%;
                }
            }
        }
    }

    @media(min-width: 768px){
        .transport-block{
            max-width: 579.19px;
    
            .transport-row:first-child{
                margin-top: 10px;
            }
    
            .transport-row{
                flex-direction: row;
                align-items: flex-start;

                .transport-first-column, .transport-second-column{
                    width: 50%;
                }
                
                .transport-first-column{
                    p{
                        font-size: 18px;
                        line-height: 23px;
                        letter-spacing: 0em;
                        margin: 0px;
                    }
                }
    
                .transport-second-column{
                    p{
                        font-size: 18px;
                        line-height: 23px;
                        letter-spacing: 0em;
                    }
                }
            }
        }
    }

    @media(min-width: 1024px){
        .transport-block{
            max-width: 828px;
    
            .transport-row:first-child{
                margin-top: 15px;
            }
    
            .transport-row{
                .transport-first-column{
                    p{
                        font-size: 20px;
                        line-height: 25px;
                        letter-spacing: 0em;
                    }
                }
    
                .transport-second-column{
                    p{
                        font-size: 24px;
                        line-height: 30px;
                        letter-spacing: 0em;
                    }
                }
            }
        }
    }

    // Block de formulari de contacte
    .form-contact-block{
        border-radius: 10px;
        background: #ECECEC;
        width: calc(100vw - 40px);
        max-width: 996px;
        height: 536px;
        padding: 15px;
        margin: auto;

        .form-container{

            form{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                width: 100%;

                .margin-right{
                    margin-right: 0px;
                }

                .form-group.input{
                    width: 100%;
    
                    input{
                        width: calc(100% - 7px);
                        background: transparent;
                        border: none;
                        border-bottom: 1px solid black;
                        outline: none;
                        padding: 7px 4px 5px;
                    }
    
                    input, input::placeholder{
                        color: #000;
                    }
                }
    
                input, input::placeholder, textarea, textarea::placeholder{
                    color: #000;
                    font-family: SohneBuch;
                    font-size: 18px;
                    line-height: 126%;
                }
    
                .form-group.textarea{
                    width: 100%;
    
                    textarea{
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        padding: 8px 0px;
                        height: 300px;
                        resize: vertical;
                    }
    
                    textarea, textarea::placeholder{
                        color: #C3C3C3;
                    }
                }
    
                .submit-container{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-end;

                    .submit-button{
                        color: #000;
                        text-align: center;
                        font-family: SohneKraftig;
                        font-size: 20px;
                        line-height: 130%;
                        letter-spacing: 0.2px;
                        border-radius: 34.36px;
                        border: 1px solid #000;
                        padding: 9px 18px;
                    }
                }
            }
        }
    }

    @media(min-width: 768px){
        .form-contact-block{
            max-width: 996px;
            height: 403px;
            padding: 15px;
    
            .form-container{

                form{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-start;
    
                    .margin-right{
                        margin-right: 25px;
                    }
    
                    .form-group.input{
                        width: 47%;
        
                        input{
                            width: 100%;
                            background: transparent;
                            border: none;
                            border-bottom: 1px solid black;
                            outline: none;
                            padding: 7px 4px 5px;
                        }
        
                        input, input::placeholder{
                            color: #000;
                        }
                    }
        
                    input, input::placeholder, textarea, textarea::placeholder{
                        color: #000;
                        font-family: SohneBuch;
                        font-size: 20px;
                        line-height: 130%;
                    }
        
                    .form-group.textarea{
                        width: 100%;
        
                        textarea{
                            width: 100%;
                            background-color: transparent;
                            border: none;
                            padding: 8px 0px;
                            height: 255px;
                            resize: vertical;
                        }
        
                        textarea, textarea::placeholder{
                            color: #C3C3C3;
                        }
                    }
        
                    .submit-container{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-end;
    
                        .submit-button{
                            color: #000;
                            text-align: center;
                            font-family: SohneKraftig;
                            font-size: 20px;
                            line-height: 130%;
                            letter-spacing: 0.2px;
                            border-radius: 34.36px;
                            border: 1px solid #000;
                            padding: 9px 18px;
                        }
                    }
                }
            }
        }
    }

    // Modal de formulari de contacte
    .form-contact-modal{
        border-radius: 10px;
        background: #ECECEC;
        // box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
        width: calc(100vw - 40px);
        max-width: 996px;
        height: 580px;
        padding: 15px;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        z-index: 999;

        .close-button{
            width: max-content;
            float: right;
        }

        .form-container{
            margin-top: 44px;

            form{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-start;
                width: 100%;

                .margin-right{
                    margin-right: 0px;
                }

                .form-group.input{
                    width: 100%;
    
                    input{
                        width: calc(100% - 7px);
                        background: transparent;
                        border: none;
                        border-bottom: 1px solid black;
                        outline: none;
                        padding: 7px 4px 5px;
                    }
    
                    input, input::placeholder{
                        color: #000;
                    }
                }
    
                input, input::placeholder, textarea, textarea::placeholder{
                    color: #000;
                    font-family: SohneBuch;
                    font-size: 18px;
                    line-height: 126%;
                }
    
                .form-group.textarea{
                    width: 100%;
    
                    textarea{
                        width: 100%;
                        background-color: transparent;
                        border: none;
                        padding: 8px 0px;
                        height: 300px;
                        resize: vertical;
                    }
    
                    textarea, textarea::placeholder{
                        color: #C3C3C3;
                    }
                }
    
                .submit-container{
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: flex-end;

                    .submit-button{
                        color: #000;
                        text-align: center;
                        font-family: SohneKraftig;
                        font-size: 20px;
                        line-height: 130%;
                        letter-spacing: 0.2px;
                        border-radius: 34.36px;
                        border: 1px solid #000;
                        padding: 9px 18px;
                    }
                }
            }
        }
    }

    @media(min-width: 768px){
        .form-contact-modal{
            max-width: 996px;
            height: 447px;
            padding: 15px;
    
            .close-button{
                width: max-content;
                float: right;
            }
    
            .form-container{
                margin-top: 0px;

                form{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    align-items: center;
                    justify-content: flex-start;
    
                    .margin-right{
                        margin-right: 25px;
                    }
    
                    .form-group.input{
                        width: 47%;
        
                        input{
                            width: 100%;
                            background: transparent;
                            border: none;
                            border-bottom: 1px solid black;
                            outline: none;
                            padding: 7px 4px 5px;
                        }
        
                        input, input::placeholder{
                            color: #000;
                        }
                    }
        
                    input, input::placeholder, textarea, textarea::placeholder{
                        color: #000;
                        font-family: SohneBuch;
                        font-size: 20px;
                        line-height: 130%;
                    }
        
                    .form-group.textarea{
                        width: 100%;
        
                        textarea{
                            width: 100%;
                            background-color: transparent;
                            border: none;
                            padding: 8px 0px;
                            height: 255px;
                            resize: vertical;
                        }
        
                        textarea, textarea::placeholder{
                            color: #C3C3C3;
                        }
                    }
        
                    .submit-container{
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: flex-end;
    
                        .submit-button{
                            color: #000;
                            text-align: center;
                            font-family: SohneKraftig;
                            font-size: 20px;
                            line-height: 130%;
                            letter-spacing: 0.2px;
                            border-radius: 34.36px;
                            border: 1px solid #000;
                            padding: 9px 18px;
                        }
                    }
                }
            }
        }
    }

    // Bloc de FAQs
    .faqs-block{
        width: calc(100vw - 30px);
        margin: 20px auto;

        h6{
            color: #000;
            text-align: center;
            font-family: SohneBreitDreiviertelfettKursiv;
            font-size: 36px;
            line-height: 106%;
            text-transform: uppercase;
            margin: 0px auto;
        }

        .accordion {
            width: 100%;
            margin: 20px auto;

            .accordion-item {
                margin-bottom: 2px;

                .accordion-header {
                    padding: 6px 0px;
                    background-color: #ffffff;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    border-bottom: 1px solid #000000;

                    .header-title{
                        margin: 0px;
                        color: #000;
                        font-family: SohneKraftig;
                        font-size: 18px;
                        line-height: 126%;
                        letter-spacing: 0.18px;
                    }

                    .accordion-icon {
                        margin-right: 5px;
                        transition: transform 0.6s ease;
                    }
                }
                
                .accordion-content {
                    background-color: #ffffff;
                    overflow: hidden;
                    transition: height 0.6s ease;

                    .content-text{
                        margin: 0px;

                        p, h1, h2, h3, h4, h5, h6{
                            margin: 0px;
                        }
                    }
                }
        
                .accordion-content > div{
                    padding: 10px 0px;
                }
            }
            
            .accordion-item.open-accordion .accordion-icon {
                transform: rotate(135deg);
            }
        }
    }

    @media(min-width: 768px){
        .faqs-block{
            max-width: 910px;
            margin: 50px auto;
    
            h6{
                font-size: 52px;
                line-height: 52px;
            }
    
            .accordion {
                margin: 25px auto;
            }
        }
    }

    @media(min-width: 1024px){
        .faqs-block{
            max-width: 986px;
    
            .accordion {
                .accordion-item {
                    .accordion-header {
                        .header-title{
                            font-size: 20px;
                            line-height: 26px;
                            letter-spacing: 0.01em;
                        }
                    }
                    
                    .accordion-content {
                        .content-text{
                            
                        }
                    }
                }
            }
        }
    }

    // Bloc de FAQs amb 2 columnes
    .faqs-block-2-col{
        width: calc(100vw - 30px);
        margin: 20px auto;

        h6{
            color: #000;
            text-align: center;
            font-family: SohneBreitDreiviertelfettKursiv;
            font-size: 36px;
            line-height: 106%;
            text-transform: uppercase;
            margin: 0px auto;
        }

        .accordion {
            width: 100%;
            margin: 20px auto;

            .accordion-item {
                margin-bottom: 2px;

                
                .accordion-header {
                    padding: 6px 0px;
                    background-color: #ffffff;
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    
                    .header-title.uppercase-style{
                        font-family: SohneBreitDreiviertelfettKursiv;
                        font-size: 18px;
                        line-height: 22px;
                        letter-spacing: 0.18px;
                        text-transform: uppercase;
                    }
                    
                    .header-title.lowercase-style{
                        font-family: QueensCondensedBoldItalic;
                        font-size: 24px;
                        line-height: 28px;
                        letter-spacing: 0.01em;
                    }

                    .header-title{
                        margin: 0px;
                        color: #000;
                    }

                    .accordion-icon {
                        margin-right: 5px;
                        transition: transform 0.6s ease;
                    }
                }
                
                .accordion-content {
                    background-color: #ffffff;
                    overflow: hidden;
                    transition: height 0.6s ease;

                    .content-text{
                        margin: 0px;

                        p, h1, h2, h3, h4, h5, h6{
                            margin: 0px;
                        }
                    }
                }
        
                .accordion-content > div{
                    padding: 10px 0px;
                }
            }
            
            .accordion-item.open-accordion .accordion-icon {
                transform: rotate(135deg);
            }
        }
    }

    @media(min-width: 768px){
        .faqs-block-2-col{
            max-width: 920px;
            margin: 50px auto;
            width: calc(100vw - 10px);
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start;
    
            h6{
                font-size: 52px;
                line-height: 52px;
            }
    
            .accordion {
                margin: 25px auto;
                width: calc(50% - 20px);
            }
        }
    }

    @media(min-width: 1024px){
        .faqs-block-2-col{
            max-width: 1010px;
        }
    }

    // Bloc de Text
    .text-block{
        width: calc(100vw - 30px);
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        margin: 25px auto;

        .text-container{

            p, h1, h2, h3, h4, h5, h6{
                margin: 0px;
            }
        }

        .white-link-container, .white-button{
            background-color: #FFFFFF;
            padding: 6.5px 17px;
            border-radius: 50px;
            border: 1px solid rgb(38, 38, 38);
            width: max-content;
            margin-left: auto;
            margin-right: auto;
            max-width: calc(100% - 34px);
        }

        .white-link, .white-button{
            color: #000;
            text-align: center;
            margin-top: 15px;
            text-decoration: none;
            font-family: SohneKraftig;
            font-size: 18px;
            line-height: 23px;
            letter-spacing: 0em;
        }
    }

    .text-button{
        margin-top: 20px;
    }


    .text-block.text-center{
        text-align: center;
    }

    @media(min-width: 768px){
        .text-block{
            margin: 50px auto;
            max-width: 777.47px;
        }
    }

    @media(min-width: 1024px){
        .text-block{
            max-width: 1000px;

            .white-link, .white-button{
                font-size: 20px;
                line-height: 130%;
                letter-spacing: 0.2px;
            }
        }
    }
}

@media(min-width: 768px){
    .web-page{
        padding: 0px 12.5px;
        
        .blocks-full-width{
            margin: 0px calc(-12.5px);
        }
    }

}

.mt-2{
    margin-top: 12px;
}