// Input Text
.admin-text-field{
    input{
        height: 32px;
        font-size: 14px;
        padding: 4px 8px;
        border-radius: 6px;
        font-family: AdminFontRegular;
    }
    label{
        font-size: 14px;
        font-family: AdminFontMedium;
        color: $font-grey;
    }
}

// Input Select
.admin-input-select{
    label{
        font-size: 14px;
        font-family: AdminFontMedium;
        color: $font-grey;
    }
    
    .MuiSelect-select{
        height: 32px !important;
        font-size: 14px;
        padding: 4px 8px;
        border-radius: 6px;
        font-family: AdminFontRegular;
    }
}

// Input Textarea
.admin-textarea{
    padding: 4px 8px;
    font-size: 14px;
    font-family: AdminFontRegular;
    border: 1px solid #c8c8c8;
    border-radius: 5px;
}
.mui-textarea::placeholder{
    font-family: AdminFontMedium;
    color: $font-grey;
}

// Input Checkbox
.admin-input-checkbox{
    .MuiFormControlLabel-label{
        font-family: AdminFontMedium;
        font-size: 14px;
    }

    .MuiSwitch-colorPrimary.Mui-checked{
        color: $primary !important;
    }

    .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track{
        background: $primary !important;
    }

    .MuiSwitch-track{
        background: $font-grey !important;
    }
    
    svg{
        color: #c8c8c8;
    }
}

// .admin-form{
//     label.Mui-focused{
//         color: rgb(47, 60, 194) !important;
//     }

//     .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
//         border-color: rgb(47, 60, 194) !important;
//     }
// }

.admin-tabs{
    a.Mui-selected{
        color: #E30613 !important;
    }

    .MuiTabs-indicator{
        background-color: #E30613 !important;
    }
}

#admin-content{
    .rdw-editor-toolbar, .rdw-editor-main{
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.23);
    }
    .public-DraftStyleDefault-block {
        margin: 4px 0;
    }
    .rdw-editor-main {
        padding: 4px 5px 0;
    }
    .input-disable-black{
        input{
            -webkit-text-fill-color: rgba(0, 0, 0, 0.87);
        }
    }
}