.top-bar{
  background-color: transparent;
  padding: 16px 24px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: calc(100% - 48px);
  max-width: 980px;

  div.button-container{
    width: max-content;
    float: right;
  }

  button.menu-button{
    padding: 3.125vw;
    width: max-content;
    border: none;

    .menu-icon{
      width: 3.75vw;
      height: 3.75vw;
    }
  }
  
  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.modal{
  padding: 15px 24px;
  width: calc(100vw - 48px);
  height: 100vh;
  background-color: red;

  .button-container{
    width: max-content;
    float: right;
    position: relative;
    z-index: 2;

    button.close-button{
      padding: 3.125vw;
      width: max-content;
      border: none;
  
      .close-icon{
        width: 3.75vw;
        height: 3.75vw;
      }
    }
  }

  .icon{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menu-list{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: max-content;
    width: calc(100% - 48px);

    a{
      text-decoration: none;
      
      h2, span{
        text-align: center;
        margin-top: 0px;
        margin-bottom: 3.75vw;
        display: block;
      }

      .font-queens{
        font-family: QueensCondensedBoldIt, serif;
        -webkit-font-smoothing: antialiased;
        font-size: 18.75vw;
        line-height: 20vw;
        text-align: center;
        color: $content-on-color;
        padding-bottom: 1.25vw;
      }

      .font-sohne{
        font-family: SohneBreitDreiviertelfettKursiv, serif;
        font-size: 12.5vw;
        line-height: 15vw;
        text-align: center;
        color: $content-on-color;
        padding-bottom: 3.75vw;
        text-transform: uppercase;
      }
    }

    a:not(:last-child){
      h2, span{
        border-bottom: 0.2vw solid $border-on-color;
      }
    }
  }

  .texto-legal-container{
    position: absolute;
    bottom: 41px;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    a{
      text-decoration: none;
      font-family: SohneBreitBuch;
      font-size: 5vw;
      line-height: 5.625vw;
      text-align: center;
      color: $content-on-color;
      margin: 0px;
    }
  }
}

@media(min-width: 981px){
  .top-bar{
    button.menu-button{
      padding: 30px;
  
      .menu-icon{
        width: 36px;
        height: 36px;
      }
    }
  }
  
  .modal{
    .button-container{
      button.close-button{
        padding: 30px;
    
        .close-icon{
          width: 36px;
          height: 36px;
        }
      }
    }
  
    .menu-list{
      a{
        h2, span{
          margin-bottom: 36px;
          display: block;
        }
  
        .font-queens{
          font-size: 183px;
          line-height: 187px;
          padding-bottom: 12px;
        }
  
        .font-sohne{
          font-size: 122px;
          line-height: 130px;
          padding-bottom: 36px;
        }
      }
  
      a:not(:last-child){
        h2, span{
          border-bottom: 1.9px solid $border-on-color;
        }
      }
    }
  }
}
