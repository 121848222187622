$primary: #E30613;
$primaryOpacity: rgba(227, 6, 19, 0.2);

$font-primary: #414042;
$font-light: #fff;
$font-grey: #778;
$font-dark: #1A1B25;

$border-light: #fff;
$border-grey-light: #eee;
$border-grey: #c9c9c9;
$boder-grey-dark: #444444;

$background-light: #fff;
$background-grey-light: #F6F8FA;
$background-grey: #a7a7a7;
$background-grey-dark: #363636;
$background-dark: #151515;
$background-dark-absolute: #000;

$success: #3edb93;
$danger: #ff5a5f;

:root {
    --primary-color: #E30613;
}
