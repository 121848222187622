@media(max-width: 767px){
    .dynamic-html-text{
        [style*="font-size: 8px"] {
            font-size: 7px !important;
        }
        [style*="font-size: 9px"] {
            font-size: 8px !important;
        }
        [style*="font-size: 10px"] {
            font-size: 9px !important;
        }
        [style*="font-size: 11px"] {
            font-size: 10px !important;
        }
        [style*="font-size: 13px"] {
            font-size: 12px !important;
        }
        [style*="font-size: 14px"] {
            font-size: 12px !important;
        }
        [style*="font-size: 16px"] {
            font-size: 13px !important;
        }
        [style*="font-size: 18px"] {
            font-size: 13px !important;
        }
        [style*="font-size: 20px"] {
            font-size: 18px !important;
        }
        [style*="font-size: 24px"] {
            font-size: 19px !important;
        }
        [style*="font-size: 28px"] {
            font-size: 20px !important;
        }
        [style*="font-size: 30px"] {
            font-size: 22px !important;
        }
        [style*="font-size: 31px"] {
            font-size: 24px !important;
        }
        [style*="font-size: 36px"] {
            font-size: 25px !important;
        }
        [style*="font-size: 37px"] {
            font-size: 26px !important;
        }
        [style*="font-size: 38px"] {
            font-size: 27px !important;
        }
        [style*="font-size: 45px"] {
            font-size: 28px !important;
        }
        [style*="font-size: 48px"] {
            font-size: 29px !important;
        }
        [style*="font-size: 50px"] {
            font-size: 30px !important;
        }
        [style*="font-size: 52px"] {
            font-size: 30px !important;
        }
        [style*="font-size: 60px"] {
            font-size: 32px !important;
        }
        [style*="font-size: 72px"] {
            font-size: 35px !important;
        }
        [style*="font-size: 86px"] {
            font-size: 37px !important;
        }
        [style*="font-size: 96px"] {
            font-size: 39px !important;
        }
    }
}